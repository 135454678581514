import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import _ from "lodash"
import helpers from "helpers"

export const api = createApi({
	reducerPath: "api",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_SERVER,
		//baseUrl: "https://cv-server.cbashome.com",
		prepareHeaders: (headers, { getState }) => {
			try {
				const token = localStorage.getItem("user")
				if (token) {
					headers.set("Authorization", `Bearer ${token}`)
				}
				return headers
			} catch (e) {
				console.log(e)
			}
		},
	}),
	endpoints: (builder) => ({
		getPayload: builder.query({
			query: () => `getPayload`,
			providesTags: ["payload"],
		}),
		login: builder.mutation({
			query: ({ email, password }) => ({
				url: "/auth/login",
				method: "POST",
				body: { email, password },
			}),
		}),
		reset: builder.mutation({
			query: ({ email }) => ({
				url: "/auth/reset",
				method: "POST",
				body: { email },
			}),
		}),
		mainRetrieve: builder.query({
			query: (d) => {
				let c = _.cloneDeep(d)
				if (c?.tags) delete c.tags
				return {
					url: "/mainRetrieve",
					method: "POST",
					body: { ...c },
				}
			},
			providesTags: (result, error, d) => d?.tags || [],
		}),
		mainIngest: builder.mutation({
			query: (body) => {
				let c = _.cloneDeep(body)
				if (c?.tags) delete c.tags
				return {
					url: "/mainIngest",
					method: "POST",
					body: c,
				}
			},
			invalidatesTags: (result, error, d) => d.tags,
		}),
		createEntries: builder.mutation({
			query: (formData) => {
				return {
					url: "/createEntries",
					method: "POST",
					body: formData,
				}
			},
			invalidatesTags: ["entries"],
			requestHandler: async (formData, { dispatch, getState }) => {
				try {
					const response = await fetch("/createEntries", {
						method: "POST",
						body: formData,
					})
					if (!response.ok) {
						throw new Error("Request failed")
					}
					const data = await response.json()
					return { data }
				} catch (error) {
					return { error: error.message }
				}
			},
		}),
		modifyEntry: builder.mutation({
			query: (formData) => {
				return {
					url: "/modifyEntry",
					method: "POST",
					body: formData,
				}
			},
			invalidatesTags: (result, error, arg) => ["entries"],
			requestHandler: async (formData, { dispatch, getState }) => {
				try {
					const response = await fetch("/modifyEntry", {
						method: "POST",
						body: formData,
					})
					if (!response.ok) {
						throw new Error("Request failed")
					}
					const data = await response.json()
					return { data }
				} catch (error) {
					return { error: error.message }
				}
			},
		}),
		modifyFloorplan: builder.mutation({
			query: (formData) => {
				return {
					url: "/modifyFloorplan",
					method: "POST",
					body: formData,
				}
			},
			invalidatesTags: (result, error, arg) => ["floorplans", "subdivisions"],
			requestHandler: async (formData, { dispatch, getState }) => {
				try {
					const response = await fetch("/modifyFloorplan", {
						method: "POST",
						body: formData,
					})
					if (!response.ok) {
						throw new Error("Request failed")
					}
					const data = await response.json()
					return { data }
				} catch (error) {
					return { error: error.message }
				}
			},
		}),
		modifySubdivision: builder.mutation({
			query: (formData) => {
				return {
					url: "/modifySubdivision",
					method: "POST",
					body: formData,
				}
			},
			invalidatesTags: (result, error, arg) => ["subdivisions", "floorplans"],
			requestHandler: async (formData, { dispatch, getState }) => {
				try {
					const response = await fetch("/modifySubdivision", {
						method: "POST",
						body: formData,
					})
					if (!response.ok) {
						throw new Error("Request failed")
					}
					const data = await response.json()
					return { data }
				} catch (error) {
					return { error: error.message }
				}
			},
		}),
		mainPatch: builder.mutation({
			query: (body) => {
				let c = _.cloneDeep(body)
				if (c?.tags) delete c.tags
				return {
					url: "/mainPatch",
					method: "PATCH",
					body: c,
				}
			},
			invalidatesTags: (result, error, d) => d.tags,
			prepareHeaders: (headers, { getState }) => {
				try {
					const token = localStorage.getItem("user")
					if (token) {
						headers.set("Authorization", `Bearer ${token}`)
					}
					return headers
				} catch (e) {
					console.log(e)
				}
			},
		}),
		createBuilder: builder.mutation({
			query: (body) => ({
				url: "/createBuilder",
				method: "POST",
				body,
			}),
			invalidatesTags: ["builders"],
		}),
		changePassword: builder.mutation({
			query: (body) => ({
				url: "/changePassword",
				method: "POST",
				body,
			}),
			invalidatesTags: [],
		}),
		getData: builder.query({
			query: (d) => {
				let c = _.cloneDeep(d)
				if (c?.tags) delete c.tags
				return {
					url: "/getData",
					method: "POST",
					body: { ...c },
				}
			},
			providesTags: ["data"],
		}),
		buildExcel: builder.query({
			query: (body) => {
				return {
					url: "/buildExcel",
					method: "POST",
					body,
				}
			},
		}),
	}),
	keepUnusedDataFor: process.env.REACT_APP_DEV === "1" ? 0 : 60, // This disables caching - used in dev
	tagTypes: ["users", "builders"],
})

export const {
	useMainRetrieveQuery,
	useLazyMainRetrieveQuery,
	useGetPayloadQuery,
	useLoginMutation,
	useResetMutation,
	useMainIngestMutation,
	useChangePasswordMutation,
	useCreateEntriesMutation,
	useModifyEntryMutation,
	useModifyFloorplanMutation,
	useModifySubdivisionMutation,
	useMainPatchMutation,
	useGetDataQuery,
	useLazyBuildExcelQuery,
} = api
