import React from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { useChangePasswordMutation } from "services/api"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"

const validationSchema = Yup.object({
	password: Yup.string()
		.min(8, "Password must be at least 8 characters")
		.matches(/[A-Z]/, "Password must contain at least one capital letter")
		.required("Required"),
	verify_password: Yup.string()
		.oneOf([Yup.ref("password")], "Passwords must match")
		.required("Required"),
})

function Onboard() {
	const [create] = useChangePasswordMutation()
	const navigate = useNavigate()

	const params = new URLSearchParams(window.location.search)
	const token = params.get("token")

	if(!token) return "Invalid token"

	return (
		<div className="">
			<div className="text-center">
				<img
					src="https://cbas-primary.s3.us-east-2.amazonaws.com/logo-color.svg"
					className="w-2/12"
					alt="Logo"
				/>
			</div>
			<div className="flex justify-center">
				<Formik
					initialValues={{
						password: "",
						verify_password: "",
					}}
					validationSchema={validationSchema}
					onSubmit={async (values, { resetForm, setSubmitting }) => {
						let resp = await create({ ...values, token, action: "changePassword", tags: [""] })
						if(resp.data?.status === 200) {
							navigate("/login")
						}
					}}>
					<Form className="onboardForm">
						<div>
							<label>Password</label>
							<Field type="password" name="password" />
							<ErrorMessage name="password" component="div" className="text-red-500" />
						</div>
						<div className="mt-4">
							<label>Verify Password</label>
							<Field type="password" name="verify_password" />
							<ErrorMessage name="verify_password" component="div" className="text-red-500" />
						</div>
						<button type="submit" className="mt-8 w-full !rounded-none">
							Submit
						</button>
					</Form>
				</Formik>
			</div>
		</div>
	)
}

export default Onboard
