import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import Admin from "./Admin"
import Onboard from "./Onboard"
import Login from "./components/Login"
import Logout from "./Logout"
import Reset from "./Reset"
import ResetPassword from "./Reset-Password"
import { Provider } from "react-redux"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { store } from "store"
import "css/global.scss"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<Provider store={store}>
		<BrowserRouter>
			<Suspense fallback={<div className="w-full h-full fixed flex justify-center items-center">fallback</div>}>
				<Routes>
					<Route path="/login" element={<Login />} name="Login" />
					<Route path="/reset" element={<Reset />} name="Reset" />
					<Route path="/reset-password" element={<ResetPassword />} name="Reset Password" />
					<Route path="/logout" element={<Logout />} name="Logout" />
					<Route path="/admin/*" element={<Admin />} name="Admin" />
					<Route path="/onboard" element={<Onboard />} name="Onboard" />
					<Route path="/" element={<App />} name="App" />
				</Routes>
			</Suspense>
		</BrowserRouter>
		<ToastContainer position="bottom-left" autoClose={2000} />
	</Provider>
)
