import React, { useEffect, useState, useRef } from "react"
import FullTable from "components/shared/FullTable"
import { useMainIngestMutation, useMainPatchMutation, useMainRetrieveQuery } from "services/api"
import { Formik, Form, Field, ErrorMessage } from "formik"
import helpers from "helpers"
import FormError from "components/shared/FormError"
import NumberField from "components/shared/NumberField"
import Select from "components/Select"
import * as Yup from "yup"
import { toast } from "react-toastify"
import Modal from "react-responsive-modal"
import _ from "lodash"
import DateField from "components/shared/DateField"
import LatLngPicker from "components/back/LatLngPicker"

function Sections() {
	const q = useMainRetrieveQuery({ action: "getSections", tags: ["sections"] })
	//const neighborhoods = useMainRetrieveQuery({ action: "getNeighborhoods", tags: ["neighborhoods"] })
	const muds = useMainRetrieveQuery({ action: "getMuds", tags: ["muds"] })
	const subdivisions = useMainRetrieveQuery({ action: "getSubdivisions", tags: ["subdivisions"] })
	const builders = useMainRetrieveQuery({ action: "getBuilders", tags: ["builders"] })
	//const schools = useMainRetrieveQuery({ action: "getSchools", tags: ["schools"] })
	const districts = useMainRetrieveQuery({ action: "getSchoolDistricts", tags: ["school_districts"] })
	const [create, createData] = useMainIngestMutation()
	const [patch, patchData] = useMainPatchMutation()
	const [sections, setSections] = useState([])
	const [editData, setEditData] = useState(null)
	const [editModal, setEditModal] = useState(false)
	const [confirmDelete, setConfirmDelete] = useState(false)
	const [markerWipe, triggerMarkerWipe] = useState(false)
	let model = {
		name: "",
		quarter_start: "",
		quarter_complete: "",
		base_price: "",
		mud: "",
		lat: "",
		lng: "",
		lot_types_builders: [],
		plat_date_prelim: "",
		model_park: null,
	}
	useEffect(() => {
		if (q.data) {
			setSections(buildRows(q.data))
		}
	}, [q])

	const buildRows = (data) => {
		let ret = []
		_.forEach(data, (item) => {
			ret.push({
				...item,
				edit: (
					<i
						className="fa-solid fa-pen-field cursor-pointer text-primary"
						onClick={() => {
							setEditData(item)
							setEditModal(true)
						}}></i>
				),
			})
		})

		return ret
	}

	const validation = () => {
		return Yup.object().shape({
			name: Yup.string().required("Required"),
			subdivision: Yup.number().required("Required"),
			//quarter_start: Yup.number().required("Required"),
		})
	}
	const columns = [
		{
			id: "id",
			header: "ID",
		},
		{
			id: "name",
			header: "Name",
		},
		{
			id: "subdivision",
			header: "Subdivision",
			accessorFn: (row) => helpers.translateID("subdivision", row, subdivisions),
		},
		{
			id: "public_name",
			header: "Public Name",
			accessorFn: (row) => helpers.colPublicName(row, subdivisions),
		},
		{
			id: "communityName",
			header: "Community",
		},
		{
			id: "edit",
			header: "Edit",
			filterable: false,
		},
	]
	const DisplayForm = (props) => {
		let init = _.clone(model)
		if (editData && !props?.new) init = editData
		return (
			<Formik
				initialValues={init}
				validationSchema={validation}
				onSubmit={async (values, { resetForm, setSubmitting }) => {
					let resp
					values.lot_types_builders = JSON.stringify(values.lot_types_builders)
					if (props?.new) {
						resp = await create({ ...values, action: "createSection", tags: ["sections"] })
					} else {
						resp = await patch({
							...values,
							type: "sections",
							action: "updateResource",
							tags: ["sections"],
						})
					}
					if (resp.data?.status === 200) {
						toast.success(resp.data.message)
						resetForm()
						setEditData(null)
						setEditModal(false)
						triggerMarkerWipe(!markerWipe)
					} else if (resp.data?.status === 400) {
						toast.error("Error creating user.")
					}
					setSubmitting(false)
				}}>
				{(f) => (
					<>
						<Form>
							{/* console.log(f) */}
							<ul className="formList">
								<li className={helpers.formField(f, "name")}>
									<ErrorMessage name="name" component={FormError} />
									<label>Name</label>
									<Field type="text" name="name" />
								</li>
								<li className={helpers.formField(f, "subdivision")}>
									<ErrorMessage name="subdivision" component={FormError} className="text-red-500" />
									<label>Subdivision</label>
									<Select
										name="subdivision"
										value={f.values.subdivision}
										onChange={f.setFieldValue}
										options={helpers.buildSubdivisions(subdivisions)}
										onBlur={f.setFieldTouched}
										clearable
									/>
								</li>
								<li className={helpers.formField(f, "mud")}>
									<ErrorMessage name="mud" component={FormError} className="text-red-500" />
									<label>MUD</label>
									<Select
										name="mud"
										value={f.values.mud}
										isClearable={true}
										onChange={f.setFieldValue}
										options={helpers.buildOpts(muds)}
										clearable
										onBlur={f.setFieldTouched}
									/>
								</li>
								<li className={helpers.formField(f, "platted_lots")}>
									<ErrorMessage name="platted_lots" component={FormError} />
									<label>Platted Lots</label>
									<NumberField
										name="platted_lots"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.platted_lots}
										error={f.errors.platted_lots}
										value={f.values.platted_lots}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "quarter_start")}>
									<ErrorMessage name="quarter_start" component={FormError} className="text-red-500" />
									<label>Quarter Start</label>
									<Select
										name="quarter_start"
										value={f.values.quarter_start}
										onChange={f.setFieldValue}
										options={helpers.buildQuarters({ allTime: true })}
										onBlur={f.setFieldTouched}
										clearable
									/>
								</li>
								<li className={helpers.formField(f, "quarter_complete")}>
									<ErrorMessage
										name="quarter_complete"
										component={FormError}
										className="text-red-500"
									/>
									<label>Quarter Complete</label>
									<Select
										name="quarter_complete"
										value={f.values.quarter_complete}
										onChange={f.setFieldValue}
										options={helpers.buildQuarters({ allTime: true })}
										onBlur={f.setFieldTouched}
										clearable
									/>
								</li>
								<li className={helpers.formField(f, "base_price")}>
									<ErrorMessage name="base_price" component={FormError} />
									<label>Base Price</label>
									<NumberField
										name="base_price"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.base_price}
										error={f.errors.base_price}
										value={f.values.base_price}
										thousandSeparator={true}
										numeric={true}
										prefix={"$"}
									/>
								</li>
								<li className={helpers.formField(f, "plat_date_prelim")}>
									<ErrorMessage name="plat_date_prelim" component={FormError} />
									<label>Preliminary Plat Date</label>
									<DateField
										name="plat_date_prelim"
										value={f.values.plat_date_prelim}
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.plat_date_prelim}
										error={f.errors.plat_date_prelim}
									/>
								</li>
								<li className={helpers.formField(f, "plat_date_recorded")}>
									<ErrorMessage name="plat_date_recorded" component={FormError} />
									<label>Recorded Plat Date</label>
									<DateField
										name="plat_date_recorded"
										value={f.values.plat_date_recorded}
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.plat_date_recorded}
										error={f.errors.plat_date_recorded}
									/>
								</li>
								<li className={helpers.formField(f, "lat")}>
									<ErrorMessage name="lat" component={FormError} />
									<label>Latitude</label>
									<NumberField
										name="lat"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.lat}
										error={f.errors.lat}
										value={f.values.lat}
										thousandSeparator={true}
										float={true}
										decimals={8}
									/>
								</li>
								<li className={helpers.formField(f, "lng")}>
									<ErrorMessage name="lng" component={FormError} />
									<label>Longitude</label>
									<NumberField
										name="lng"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.lng}
										error={f.errors.lng}
										value={f.values.lng}
										thousandSeparator={true}
										decimals={8}
										float={true}
									/>
								</li>
								<li className={helpers.formField(f, "school_district")}>
									<ErrorMessage
										name="school_district"
										component={FormError}
										className="text-red-500"
									/>
									<label>School District</label>
									<Select
										name="school_district"
										value={f.values.school_district}
										onChange={f.setFieldValue}
										options={helpers.buildOpts(districts)}
										onBlur={f.setFieldTouched}
										clearable
									/>
								</li>
								<li className={helpers.formField(f, "model_park")}>
									<ErrorMessage name="model_park" component={FormError} className="text-red-500" />
									<label>Is Model Park?</label>
									<Select
										name="model_park"
										value={f.values.model_park}
										onChange={f.setFieldValue}
										options={[
											{ value: 0, label: "No" },
											{ value: 1, label: "Yes" },
										]}
										onBlur={f.setFieldTouched}
										clearable
									/>
								</li>
								<li className={helpers.formField(f, "btr")}>
									<ErrorMessage name="btr" component={FormError} className="text-red-500" />
									<label>Is BTR?</label>
									<Select
										name="btr"
										value={f.values.btr}
										onChange={f.setFieldValue}
										options={[
											{ value: 0, label: "No" },
											{ value: 1, label: "Yes" },
										]}
										onBlur={f.setFieldTouched}
										clearable
									/>
								</li>
							</ul>
							<h3 className="mt-24">Select Center Point</h3>
							<LatLngPicker height={400} formik={f} {...props} triggerWipe={markerWipe} />
							<button type="submit" disabled={f.isSubmitting} className="mt-12">
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		)
	}

	return (
		<div className="">
			<div className="group">
				<h2>Add Sections</h2>
				<DisplayForm new={true} />
			</div>
			<h2 className="mt-12">Current Sections</h2>
			<FullTable data={sections} columns={columns} admin={true} />
			<Modal open={editModal} onClose={() => setEditModal(false)} center>
				<div className="flex">
					<h2>Edit Sections</h2>
					<p>
						<span
							className="btn !bg-red-500 !text-xs ml-5"
							onClick={async () => {
								if(!confirmDelete) {
									setConfirmDelete(!confirmDelete)
								}
								else {
									let resp = await patch({ id: editData.id, action: "deleteSection", tags: ["sections"] })
									setEditModal(false)
									setConfirmDelete(false)
									triggerMarkerWipe(!markerWipe)
									toast.success(resp?.data?.message)
								}
								
							}}>
							{confirmDelete ? "Confirm Delete?" : "Delete Section"}
						</span>
					</p>
				</div>
				<DisplayForm />
			</Modal>
		</div>
	)
}

export default Sections
