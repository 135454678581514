import React, { useEffect, useState, useRef } from "react"
import {
	useGetPayloadQuery,
	useGetDataQuery,
	useMainIngestMutation,
	useLazyMainRetrieveQuery,
	useMainRetrieveQuery,
} from "services/api"
import { useNavigate } from "react-router-dom"
import mapboxgl from "mapbox-gl"
mapboxgl.accessToken = "pk.eyJ1IjoiYWFyb25jYmFzIiwiYSI6ImNscjAwOWoybzA3dnIyam44aTJkN3Vwd2sifQ.15gmXjg-0XiY6k0dDV_vBQ"
import "mapbox-gl/dist/mapbox-gl.css"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import classNames from "classnames"
import FullTable from "components/shared/FullTable"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
import _, { update } from "lodash"
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik"
import Select from "components/Select"
import NumberField from "components/shared/NumberField"
import "react-responsive-modal/styles.css"
import GridLoader from "react-spinners/GridLoader"
import moment from "moment"
import helpers from "helpers"
//import { center, points } from "@turf/turf"
import * as turf from "@turf/turf"
import numeral from "numeral"
import { toast } from "react-toastify"
import modelColumns from "models/columns"

function App() {
	const navigate = useNavigate()
	const formikRef = useRef(null)
	const mapContainer = useRef(null)
	const [map, setMap] = useState(null)
	let [entries, setEntries] = useState([])
	const [mapStyle, setMapStyle] = useState("mapbox://styles/mapbox/streets-v11")
	const debug = false
	const drawRef = useRef(null)
	const [zoom, setZoom] = useState(11)
	const [previousData, setPreviousData] = useState([])
	const [areaName, setAreaName] = useState(null)
	const [areaSaving, setAreaSaving] = useState(false)
	const [loading, setLoading] = useState(false) // This is for the spinner
	const [selectPolygon, setSelectPolygon] = useState(false)
	const [radiusMarker, setRadiusMarker] = useState(false)
	const [radius, setRadius] = useState(3)
	const [geographicSelected, setGeographicSelected] = useState(false)
	const radiusRef = useRef(3)
	const [clickedLayer, setClickedLayer] = useState(null)
	const [bottomDrawerVisible, setBottomDrawerVisible] = useState(true)
	const [sideDrawerVisible, setSideDrawerVisible] = useState(true)
	let fields = {
		markets: "",
		submarkets: "",
		communities: "",
		neighborhoods: "",
		subdivisions: "",
		districts: [],
		muds: [],
		schools: [],
		builders: [],
		zips: [],
		counties: "",
		prices: {
			min: null,
			max: null,
		},
		lot_type_range: {
			/* min: 1,
			max: 100, */
			min: null,
			max: null,
		},
		vdls: {
			min: null,
			max: null,
		},
		futures: {
			min: null,
			max: null,
		},
		annual_starts: {
			min: null,
			max: null,
		},
		annual_closings: {
			min: null,
			max: null,
		},
		active_since: {
			min: null,
			max: null,
		},
		finished_since: {
			min: null,
			max: null,
		},
		has_future: null,
		area: {
			type: "FeatureCollection",
			features: [],
		},
		radius: {
			lat: false,
			lng: false,
			miles: false,
		},
	}
	const [selections, setSelections] = useState(_.cloneDeep(fields))
	const [filterListShowing, setFilterListShowing] = useState("subdivisions")

	const [getZips, { data: zipsData, isLoading: isLoadingZips, isSuccess: zipsSuccess }] = useLazyMainRetrieveQuery()
	const [getMuds, { data: mudsData, isLoading: isLoadingMuds, isSuccess: mudsSuccess }] = useLazyMainRetrieveQuery()
	const [getCounties, { data: countiesData, isLoading: isLoadingCounties, isSuccess: countiesSuccess }] =
		useLazyMainRetrieveQuery()
	const [getSchools, { data: schoolsData, isLoading: isLoadingSchools, isSuccess: schoolsSuccess }] =
		useLazyMainRetrieveQuery()
	const [getMarkets, { data: marketsData, isLoading: isLoadingMarkets, isSuccess: marketsSuccess }] =
		useLazyMainRetrieveQuery()
	const [getSubmarkets, { data: submarketsData, isLoading: isLoadingSubmarkets, isSuccess: submarketsSuccess }] =
		useLazyMainRetrieveQuery()
	const [getDistricts, { data: districtsData }] = useLazyMainRetrieveQuery()
	const [getAttendanceZones, { data: attendanceZones }] = useLazyMainRetrieveQuery()
	const { data, error, isLoading, isSuccess } = useGetPayloadQuery()
	//const [getAllLatest, { data: allLatest, isLoading: isLoadingAllLatest, isSuccess: allLatestSuccess }] = useMainRetrieveQuery()

	const allLatest = useMainRetrieveQuery({ action: "getAllLatest", tags: ["allLatest"] })
	const [layer, setLayer] = useState(false) // the layer on the map, like zips, counties, etc.
	const layerRef = useRef(false)
	const selectedLayerRef = useRef(null)
	const d = useGetDataQuery(selections)
	const [columns, setColumns] = useState(modelColumns.subdivisionsColumns)
	const [saveArea, saveAreaData] = useMainIngestMutation()
	const [viewing, setViewing] = useState({
		name: "subdivisions",
	}) // for the table, which data set and columns to show

	const layers = [
		"zips",
		"zips_outline",
		"zips_labels",
		"counties",
		"county_outline",
		"counties_labels",
		"markets",
		"market_outline",
		"markets_labels",
		"submarkets",
		"submarkets_outline",
		"submarkets_labels",
		"heat_starts",
		"heat_vdls",
		"heat_closings",
		"heat_futures",
		"districts",
		"districts_outline",
		"districts_labels",
		"schools_text",
		"schools_circles",
		"attendance_elementary",
		"attendance_elementary_outline",
		"attendance_elementary_labels",
		"attendance_middle",
		"attendance_middle_outline",
		"attendance_middle_labels",
		"attendance_high",
		"attendance_high_outline",
		"attendance_high_labels",
		"muds",
		"muds_outline",
		"muds_labels",
	]

	const idKey = {
		// This is the key for the ID of each layer
		zips: "Zip_Code",
		counties: "id",
		markets: "id",
		submarkets: "id",
		districts: "id",
		attendance_elementary: "id",
		attendance_middle: "id",
		attendance_high: "id",
	}

	const selectionTranslations = {
		active_since: "Active Since",
		annual_closings: "Annual Closings",
		annual_starts: "Annual Starts",
		area: "Area",
		builders: "Builders",
		communities: "Communities",
		counties: "Counties",
		districts: "Districts",
		finished_since: "Finished Since",
		futures: "Futures",
		has_future: "Has Future",
		lot_type_range: "Lot Type Range",
		markets: "Markets",
		prices: "Prices",
		radius: "Radius",
		schools: "Schools",
		subdivisions: "Subdivisions",
		submarkets: "Submarkets",
		vdls: "VDLs",
		zips: "Zips",
	}

	useEffect(() => {
		initMap()
	}, [])

	useEffect(() => {
		// Previous data initially used so that when you modify polygons that you don't fly to the coordinates again - was annoying.
		setPreviousData(d?.data?.entries)
		// layer highlighting
		if (map && layer) {
			layerChange()
			updateLayerOpacity()
		}
		if (geographicSelected === "markets") {
			layerRef.current = "markets"
			handleMarkets()
		} else if (geographicSelected === "submarkets") {
			layerRef.current = "submarkets"
			handleSubmarkets()
		} else if (geographicSelected === "districts") {
			layerRef.current = "districts"
			handleDistricts()
		} else if (geographicSelected === "schools") {
			layerRef.current = "schools"
			handleSchools()
		} else if (geographicSelected === "counties") {
			layerRef.current = "counties"
			handleCounties()
		} else if (geographicSelected === "zips") {
			layerRef.current = "zips"
			handleZips()
		} else if (geographicSelected === "muds") {
			layerRef.current = "muds"
			handleMuds()
		}
	}, [selections])

	useEffect(() => {
		mapOutSubdivisions()
		buildEntries()
	}, [d?.data?.entries])

	useEffect(() => {
		layerRef.current = layer
		if (layer) {
			layerChange()
		} else {
			removeAllLayers()
		}
	}, [layer])

	useEffect(() => {
		if (!clickedLayer) return
		handleLayerClick()
	}, [clickedLayer])

	useEffect(() => {
		paintSelections()
	}, [selectedLayerRef.current])

	useEffect(() => {
		if (radiusMarker) {
			drawRadiusCircle(radiusMarker)
			setSelections((prevSelections) => ({
				...prevSelections,
				radius: {
					...prevSelections.radius,
					miles: parseFloat(radius),
				},
			}))
		}
	}, [radius])

	useEffect(() => {
		if (viewing?.name === "subdivisions") {
			setColumns(modelColumns.subdivisionsColumns)
			mapOutSubdivisions()
		} else if (viewing?.name === "subdivision") {
			setColumns(modelColumns.subdivisionColumns)
			// Map out the sections for this subdivision
			mapOutSections()
		}
		buildEntries()
	}, [viewing])

	useEffect(() => {
		if (map) map.resize()
	}, [bottomDrawerVisible, sideDrawerVisible])

	useEffect(() => {
		if (!geographicSelected) {
			removeAllLayers()
		}
	}, [geographicSelected])

	useEffect(() => {
		if (d.isFetching) {
			setLoading(true)
		} else {
			setLoading(false)
		}
	}, [d.isFetching])

	// Check for login
	useEffect(() => {
		const user = localStorage.getItem("user")
		if (!user) {
			navigate("/login")
		}
	})

	const paintSelections = () => {
		if (map && selectedLayerRef.current) {
			_.forEach(layers, (layerId) => {
				if (layerId.includes("outline") || layerId.includes("label")) return
				if (map.getLayer(layerId)) {
					if (idKey[layerId] === "id") {
						map.setPaintProperty(layerId, "fill-opacity", [
							"case",
							["in", ["to-number", ["get", idKey[layerId]]], ["literal", selectedLayerRef.current]],
							0.6,
							0.3,
						])
					} else {
						map.setPaintProperty(layerId, "fill-opacity", [
							"case",
							["in", ["get", idKey[layerId]], ["literal", selectedLayerRef.current]],
							0.6,
							0.3,
						])
					}
				}
			})
			moveDotsLayersToTop()
		}
	}

	const layerChange = () => {
		if (!map) return
		layers.forEach((l) => {
			if (map.getLayer(l)) map.removeLayer(l)
		})
		if (!layer) {
			return
		}
		if (layer === "zip") {
			handleZips()
		} else if (layer === "muds") {
			handleMuds()
		} else if (layer === "county") {
			handleCounties()
		} else if (layer === "market") {
			handleMarkets()
		} else if (layer === "submarket") {
			handleSubmarkets()
		} else if (layer === "heat_starts") {
			handleHeatStarts()
		} else if (layer === "heat_vdls") {
			handleHeatVDLs()
		} else if (layer === "district") {
			handleDistricts()
		} else if (layer === "school") {
			handleSchools()
		} else if (layer === "attendance_elementary") {
			handleAttendanceZone("elementary")
		} else if (layer === "attendance_middle") {
			handleAttendanceZone("middle")
		} else if (layer === "attendance_high") {
			handleAttendanceZone("high")
		}

		if (map.getLayer("gl-draw-polygon-fill-inactive")) {
			map.moveLayer("gl-draw-polygon-fill-inactive")
			map.moveLayer("gl-draw-polygon-stroke-inactive")
			map.moveLayer("gl-draw-polygon-midpoint")
		}
		moveDotsLayersToTop()
	}

	const removeAllLayers = () => {
		if (!map) return
		layers.forEach((l) => {
			if (map.getLayer(l)) {
				map.removeLayer(l)
			}
		})
	}

	const handleLayerClick = () => {
		if (!formikRef.current) return
		let f = formikRef.current
		let type = clickedLayer.type
		setFilterListShowing("geographics")
		//removeMarkers()
		if (type.includes("attendance")) {
			let features = _.map(clickedLayer.features, "id")
			let store = []
			if (f.values.schools) store = _.clone(f.values.schools)
			_.forEach(features, (f) => {
				let lookup = _.find(data.schools, { id: f })
				if (lookup) {
					if (!store.includes(lookup.id)) store.push(lookup.id)
					else {
						store = store.filter((s) => s !== lookup.id)
					}
				}
			})
			if (!_.isEqual(store, f.values.schools)) {
				f.setFieldValue("schools", store)
				f.handleSubmit()
			}
		} else if (type === "submarket") {
			let features = _.map(clickedLayer.features, "CBAS_SubMk")
			let store = []
			if (f.values.submarkets) store = _.clone(f.values.submarkets)
			_.forEach(features, (f) => {
				let lookup = _.find(data.submarkets, { name: `${f}` })
				if (lookup) {
					if (!store.includes(lookup.id)) store.push(lookup.id)
					else {
						store = store.filter((s) => s !== lookup.id)
					}
				}
			})
			if (!_.isEqual(store, f.values.submarkets)) {
				f.setFieldValue("submarkets", store)
				f.handleSubmit()
			}
		} else if (type === "district") {
			let features = _.map(clickedLayer.features, "id")
			let store = []
			if (f.values.districts) store = _.clone(f.values.districts)
			_.forEach(features, (f) => {
				let lookup = _.find(data.districts, { id: f })
				if (lookup) {
					if (!store.includes(lookup.id)) store.push(lookup.id)
					else {
						store = store.filter((s) => s !== lookup.id)
					}
				}
			})
			if (!_.isEqual(store, f.values.districts)) {
				f.setFieldValue("districts", store)
				f.handleSubmit()
			}
		} else if (type === "market") {
			let features = _.map(clickedLayer.features, "CBAS_Mkt")
			let store = []
			if (f.values.markets) store = _.clone(f.values.markets)
			_.forEach(features, (f) => {
				let lookup = _.find(data.markets, { name: `${f}` })
				if (lookup) {
					if (!store.includes(lookup.id)) store.push(lookup.id)
					else {
						store = store.filter((s) => s !== lookup.id)
					}
				}
			})
			if (!_.isEqual(store, f.values.markets)) {
				f.setFieldValue("markets", store)
				f.handleSubmit()
			}
		} else if (type === "zip") {
			try {
				let store = []
				if (f.values.zips) store = _.clone(f.values.zips)
				let clicked = null
				if (Array.isArray(clickedLayer.features) && clickedLayer.features.length >= 1) {
					clicked = clickedLayer.features[0]?.Zip_Code
					clicked = clicked.toString()
				}
				if (clicked && store.includes(clicked)) {
					store = store.filter((s) => s !== clicked)
				} else if (clicked) {
					store.push(clicked)
				}
				//let store = _.map(clickedLayer.features, "Zip_Code")
				store = helpers.translateNamesToIds(store, data, "zips")
				if (!_.isEqual(store, f.values.zips)) {
					f.setFieldValue("zips", store)
					f.handleSubmit()
				}
			} catch (e) {}
		} else if (type === "county") {
			let features = _.map(clickedLayer.features, "OBJECTID")
			let store = []
			if (f.values.counties) store = _.clone(f.values.counties)
			_.forEach(features, (f) => {
				let lookup = _.find(data.counties, { external_id: `${f}` })
				if (lookup) {
					if (!store.includes(lookup.id)) store.push(lookup.id)
					else {
						store = store.filter((s) => s !== lookup.id)
					}
				}
			})
			if (!_.isEqual(store, f.values.counties)) {
				f.setFieldValue("counties", store)
				f.handleSubmit()
			}
		} else if (type === "muds") {
			let features = _.map(clickedLayer.features, "OBJECTID")
			let store = []
			if (f.values.muds) store = _.clone(f.values.muds)
			_.forEach(features, (f) => {
				let lookup = _.find(data.muds, { id: f })
				if (lookup) {
					if (!store.includes(lookup.id)) store.push(lookup.id)
					else {
						store = store.filter((s) => s !== lookup.id)
					}
				}
			})
			if (!_.isEqual(store, f.values.muds)) {
				f.setFieldValue("muds", store)
				f.handleSubmit()
			}
		}
	}

	const removeMarkers = () => {
		if (!map) return
		if (map.getLayer("dots")) map.removeLayer("dots")
		if (map.getLayer("dots-labels")) map.removeLayer("dots-labels")
		if (map.getSource("dots")) map.removeSource("dots")
	}

	const moveDotsLayersToTop = () => {
		if (!map) return
		if (map.getLayer("dots")) {
			map.moveLayer("dots")
		}
		if (map.getLayer("dots-labels")) {
			map.moveLayer("dots-labels")
		}
		if (map.getLayer("circle-layer")) {
			map.moveLayer("circle-layer")
		}
	}

	const initMap = () => {
		const map = new mapboxgl.Map({
			container: mapContainer.current,
			style: mapStyle,
			//style: "mapbox://styles/mapbox/satellite-streets-v12",
			center: [-95.36723795379703, 29.7627587122713],
			//pitch: 15,
			zoom,
			minZoom: 7,
			maxZoom: 17,
			attributionControl: false,
		})
		// Add navigation control (the +/- zoom buttons)
		map.addControl(new mapboxgl.NavigationControl(), "bottom-right")
		const Draw = new MapboxDraw({
			displayControlsDefault: false,
			controls: {
				polygon: true,
				trash: true,
			},
			// Set mapbox-gl-draw to draw by default.
			// The user does not have to click the polygon control button first.
			//defaultMode: "draw_polygon",
			styles: [
				{
					id: "gl-draw-polygon-fill-inactive",
					type: "fill",
					filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
					paint: {
						"fill-color": "#7e3bd0",
						"fill-outline-color": "#7e3bd0",
						"fill-opacity": 0.4,
					},
				},
				{
					id: "gl-draw-polygon-fill-active",
					type: "fill",
					filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
					paint: {
						"fill-color": "#2399d5",
						"fill-outline-color": "#2399d5",
						"fill-opacity": 0.4,
					},
				},
				{
					id: "gl-draw-polygon-midpoint",
					type: "circle",
					filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
					paint: {
						"circle-radius": 3,
						"circle-color": "#2399d5",
					},
				},
				{
					id: "gl-draw-polygon-stroke-inactive",
					type: "line",
					filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
					layout: {
						"line-cap": "round",
						"line-join": "round",
					},
					paint: {
						"line-color": "#7e3bd0",
						"line-width": 2,
					},
				},
				{
					id: "gl-draw-polygon-stroke-active",
					type: "line",
					filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
					layout: {
						"line-cap": "round",
						"line-join": "round",
					},
					paint: {
						"line-color": "#2399d5",
						"line-dasharray": [0.2, 2],
						"line-width": 2,
					},
				},
				{
					id: "gl-draw-line-inactive",
					type: "line",
					filter: ["all", ["==", "active", "false"], ["==", "$type", "LineString"], ["!=", "mode", "static"]],
					layout: {
						"line-cap": "round",
						"line-join": "round",
					},
					paint: {
						"line-color": "#7e3bd0",
						"line-width": 2,
					},
				},
				{
					id: "gl-draw-line-active",
					type: "line",
					filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
					layout: {
						"line-cap": "round",
						"line-join": "round",
					},
					paint: {
						"line-color": "#2399d5",
						"line-dasharray": [0.2, 2],
						"line-width": 2,
					},
				},
				{
					id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
					type: "circle",
					filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
					paint: {
						"circle-radius": 5,
						"circle-color": "#fff",
					},
				},
				{
					id: "gl-draw-polygon-and-line-vertex-inactive",
					type: "circle",
					filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
					paint: {
						"circle-radius": 3,
						"circle-color": "#2399d5",
					},
				},
				{
					id: "gl-draw-point-point-stroke-inactive",
					type: "circle",
					filter: [
						"all",
						["==", "active", "false"],
						["==", "$type", "Point"],
						["==", "meta", "feature"],
						["!=", "mode", "static"],
					],
					paint: {
						"circle-radius": 5,
						"circle-opacity": 1,
						"circle-color": "#fff",
					},
				},
				{
					id: "gl-draw-point-inactive",
					type: "circle",
					filter: [
						"all",
						["==", "active", "false"],
						["==", "$type", "Point"],
						["==", "meta", "feature"],
						["!=", "mode", "static"],
					],
					paint: {
						"circle-radius": 3,
						"circle-color": "#7e3bd0",
					},
				},
				{
					id: "gl-draw-point-stroke-active",
					type: "circle",
					filter: ["all", ["==", "$type", "Point"], ["==", "active", "true"], ["!=", "meta", "midpoint"]],
					paint: {
						"circle-radius": 7,
						"circle-color": "#fff",
					},
				},
				{
					id: "gl-draw-point-active",
					type: "circle",
					filter: ["all", ["==", "$type", "Point"], ["!=", "meta", "midpoint"], ["==", "active", "true"]],
					paint: {
						"circle-radius": 5,
						"circle-color": "#2399d5",
					},
				},
				{
					id: "gl-draw-polygon-fill-static",
					type: "fill",
					filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
					paint: {
						"fill-color": "#404040",
						"fill-outline-color": "#404040",
						"fill-opacity": 0.4,
					},
				},
				{
					id: "gl-draw-polygon-stroke-static",
					type: "line",
					filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
					layout: {
						"line-cap": "round",
						"line-join": "round",
					},
					paint: {
						"line-color": "#404040",
						"line-width": 2,
					},
				},
				{
					id: "gl-draw-line-static",
					type: "line",
					filter: ["all", ["==", "mode", "static"], ["==", "$type", "LineString"]],
					layout: {
						"line-cap": "round",
						"line-join": "round",
					},
					paint: {
						"line-color": "#404040",
						"line-width": 2,
					},
				},
				{
					id: "gl-draw-point-static",
					type: "circle",
					filter: ["all", ["==", "mode", "static"], ["==", "$type", "Point"]],
					paint: {
						"circle-radius": 5,
						"circle-color": "#404040",
					},
				},
				{
					id: "gl-draw-polygon-color-picker",
					type: "fill",
					filter: ["all", ["==", "$type", "Polygon"], ["has", "user_portColor"]],
					paint: {
						"fill-color": ["get", "user_portColor"],
						"fill-outline-color": ["get", "user_portColor"],
						"fill-opacity": 0.5,
					},
				},
				{
					id: "gl-draw-line-color-picker",
					type: "line",
					filter: ["all", ["==", "$type", "LineString"], ["has", "user_portColor"]],
					paint: {
						"line-color": ["get", "user_portColor"],
						"line-width": 2,
					},
				},
				{
					id: "gl-draw-point-color-picker",
					type: "circle",
					filter: ["all", ["==", "$type", "Point"], ["has", "user_portColor"]],
					paint: {
						"circle-radius": 3,
						"circle-color": ["get", "user_portColor"],
					},
				},
			],
		})
		drawRef.current = Draw
		map.addControl(Draw, "top-left")
		map.getCanvas().style.cursor = "pointer"

		map.on("draw.create", (e) => {
			const data = Draw.getAll()
			if (data.features.length > 1) {
				Draw.deleteAll()
				data.features.shift()
				Draw.set(data)
			}

			if (data.features.length === 1) {
				setAreaName("")
				let sels = _.cloneDeep(selections)
				sels.area = data
				setSelections(sels)
				setAreaSaving(true)
			} else {
				if (e.type !== "draw.delete") alert("Click the map to draw a polygon.")
			}
		})
		map.on("draw.delete", (e) => {
			const data = Draw.getAll()
			resetFilters(null, { draw: Draw })
			setAreaSaving(false)
			if (data.features.length > 0) {
				let sels = _.cloneDeep(selections)
				sels.area = data
				setSelections(sels)
				setAreaSaving(true)
			} else {
				if (e.type !== "draw.delete") alert("Click the map to draw a polygon.")
			}
		})
		map.on("draw.update", (e) => {
			const data = Draw.getAll()
			if (data.features.length > 0) {
				let sels = _.cloneDeep(selections)
				sels.area = data
				setSelections(sels)
				setAreaSaving(true)
			} else {
				if (e.type !== "draw.delete") alert("Click the map to draw a polygon.")
			}
		})
		map.on("draw.selectionchange", (e) => {
			if (e.features.length === 0) {
				// Deselect
				setAreaSaving(false)
			} else {
				// Select
				setAreaSaving(true)
			}
		})
		map.on("draw.modechange", (e) => {
			//
		})

		map.on("click", (e) => {
			handleClickedLayer(e, map, layer)
		})
		map.on("load", async () => {
			setMap(map)
		})
		map.on("idle", function () {})

		//map.scrollZoom.disable()
		// Clean up on unmount
		return () => {
			map.remove()
		}
	}

	const handleClickedLayer = (e, map, layer) => {
		let point = e.point
		// Query the map for features at the clicked point
		let clickedFeatures = null
		_.forEach(layers, (l) => {
			if (map.getLayer(l) && !["outline", "label"].some((phrase) => l.includes(phrase))) {
				let features = map.queryRenderedFeatures(point, {
					layers: [l],
				})
				if (features.length > 0) {
					clickedFeatures = features
				}
			}
		})
		let l = layerRef.current
		// Update the clickedLayer state only if clicked features exist
		if (clickedFeatures && !_.isEqual(clickedFeatures, clickedLayer)) {
			setClickedLayer({ type: l, features: _.map(clickedFeatures, "properties") })
		}
	}

	const drawRadiusCircle = (marker) => {
		if (!marker || !map) return
		if (map.getLayer("circle-layer")) map.removeLayer("circle-layer")
		if (map.getSource("circle-source")) map.removeSource("circle-source")

		let loc = marker.getLngLat()
		const center = [loc.lng, loc.lat]

		// Radius in miles
		const radiusInMiles = radiusRef.current

		// Create a circle using turf.js
		const circle = turf.circle(center, radiusInMiles, { units: "miles" })

		// Add the circle to the map
		map.addSource("circle-source", {
			type: "geojson",
			data: circle,
		})

		map.addLayer({
			id: "circle-layer",
			type: "fill",
			source: "circle-source",
			paint: {
				"fill-color": "#007cbf",
				"fill-opacity": 0.2,
				"fill-outline-color": "#007cbf",
			},
		})
	}

	const mapOutSubdivisions = (opts) => {
		if (JSON.stringify(d?.data?.entries) === JSON.stringify(previousData) && !radiusMarker) {
			return
		}
		if (_.isEmpty(d?.data?.entries)) {
			if (map && _.isEmpty(selections.area)) {
				map.flyTo({ center: [-95.36723795379703, 29.7627587122713], zoom: 10 })
			}
			removeMarkers()
			flyToCenter()
			return
		}
		if (map) {
			let rows = _.sortBy(d.data.entries, "name")
			removeMarkers()
			let centerStorage = []
			let geojson = {
				type: "FeatureCollection",
				features: [],
			}
			_.forEach(rows, (entry, k) => {
				if (!entry.lat || !entry.lng) return
				centerStorage.push([entry.lat, entry.lng])
				geojson.features.push({
					type: "Feature",
					properties: {
						description: `${entry.name}`,
						number: k + 1,
						...entry,
					},
					geometry: {
						type: "Point",
						coordinates: [entry.lng, entry.lat],
					},
				})
			})
			map.addSource("dots", {
				type: "geojson",
				data: geojson,
			})
			// Add the dots
			map.addLayer({
				id: "dots",
				type: "circle",
				source: "dots",
				slot: "top",
				layout: {
					"text-field": "",
					"text-pitch-alignment": "map",
				},
				paint: {
					"circle-color": "#4264fb",
					"circle-radius": 9,
					"circle-stroke-width": 2,
					"circle-stroke-color": "#ffffff",
				},
			})
			// Now add the text labels
			map.addLayer({
				id: "dots-labels",
				type: "symbol",
				source: "dots",
				slot: "top",
				layout: {
					"text-field": ["get", "number"],
					"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
					"text-size": 12,
					"text-allow-overlap": true,
				},
				paint: {
					"text-color": "#ffffff",
				},
			})
			const popup = new mapboxgl.Popup({
				closeButton: false,
				closeOnClick: false,
				className: "app__popup",
			})

			map.on("mouseenter", "dots", (e) => {
				// Change the cursor style as a UI indicator.
				map.getCanvas().style.cursor = "pointer"

				// Copy coordinates array.
				const coordinates = e.features[0].geometry.coordinates.slice()
				let html = ""
				let p = e.features[0].properties
				let prices = typeof p.prices === "object" ? JSON.parse(p.prices) : {}
				html += `<div style="app__popupInterior">`
				html += `<div><h2>${p.name}</h2></div>`
				html += `<table>`
				if (p.communityname) html += `<tr><td>Community</td><td>${p.communityName}</td></tr>`
				if (p.status) html += `<tr><td>Status</td><td>${p.status}</td></tr>`
				if (prices.min && prices.max)
					html += `<tr><td>Price Range</td><td>${numeral(prices.min).format("$0ak")} - ${numeral(
						prices.max
					).format("$0ak")}</td></tr>`
				if (p.lot_types) html += `<tr><td>Lot Types</td><td>${JSON.parse(p.lot_types).join(", ")}</td></tr>`
				if (p.builders_names)
					html += `<tr><td>Builders</td><td>${JSON.parse(p.builders_names).join(", ")}</td></tr>`
				if (p.annual_starts) html += `<tr><td>Annual Starts</td><td>${p.annual_starts}</td></tr>`
				if (p.futures) html += `<tr><td>Futures</td><td>${p.futures}</td></tr>`
				if (p.vdl) html += `<tr><td>VDL</td><td>${p.vdl}</td></tr>`
				if (p.complete_vacant) html += `<tr><td>Complete Vacant</td><td>${p.complete_vacant}</td></tr>`
				html += `</table>`

				html += `</div>`
				// Ensure that if the map is zoomed out such that multiple
				// copies of the feature are visible, the popup appears
				// over the copy being pointed to.
				while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
					coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
				}

				// Populate the popup and set its coordinates
				// based on the feature found.
				popup.setLngLat(coordinates).setHTML(html).addTo(map)
			})

			map.on("mouseleave", "dots", () => {
				map.getCanvas().style.cursor = ""
				popup.remove()
			})
			try {
				if (radiusMarker) {
					//? if radius marker, then keep the center on the marker
					let loc = radiusMarker.getLngLat()
					map.flyTo({ center: [loc.lng, loc.lat], zoom: 10 })
				} else if (geojson.features.length > 0 /*  && selections.area?.features?.length === 0 */) {
					//? normal fly to the center of the points
					if (!opts?.dontFly) {
						map.fitBounds(turf.bbox(geojson), {
							padding: 100,
							...(layer ? { maxZoom: 10 } : {}), // if a layer is set, then don't zoom in too far
						})
					}
				}
			} catch (e) {}
		}
	}

	const mapOutSections = () => {
		if (!map) return
		removeMarkers()
		let centerStorage = []
		let geojson = {
			type: "FeatureCollection",
			features: [],
		}
		_.forEach(d?.data?.entries, (entry, k) => {
			if (viewing.id !== entry.id) return
			_.forEach(entry.sections, (section, i) => {
				centerStorage.push([section.lat, section.lng])
				geojson.features.push({
					type: "Feature",
					properties: {
						description: `${section.name}`,
						number: i + 1,
						...section,
					},
					geometry: {
						type: "Point",
						coordinates: [section.lng, section.lat],
					},
				})
			})
		})
		map.addSource("dots", {
			type: "geojson",
			data: geojson,
		})
		// Add the dots
		map.addLayer({
			id: "dots",
			type: "circle",
			source: "dots",
			layout: {
				"text-field": "TEST",
			},
			paint: {
				"circle-color": "#4264fb",
				"circle-radius": 9,
				"circle-stroke-width": 2,
				"circle-stroke-color": "#ffffff",
			},
		})
		// Now add the text labels
		map.addLayer({
			id: "dots-labels",
			type: "symbol",
			source: "dots",
			layout: {
				"text-field": ["get", "number"],
				"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
				"text-size": 12,
				"text-pitch-alignment": "map",
			},
			paint: {
				"text-color": "#ffffff",
			},
		})
		const popup = new mapboxgl.Popup({
			closeButton: false,
			closeOnClick: false,
			className: "app__popup",
		})

		map.on("mouseenter", "dots", (e) => {
			// Change the cursor style as a UI indicator.
			map.getCanvas().style.cursor = "pointer"

			// Copy coordinates array.
			const coordinates = e.features[0].geometry.coordinates.slice()
			let html = ""
			let p = e.features[0].properties
			let prices = p.price_range ? JSON.parse(p.price_range) : {}
			html += `<div style="app__popupInterior">`
			html += `<div><h2>Sec ${p.name}</h2></div>`
			html += `<table>`
			if (p.communityname) html += `<tr><td>Community</td><td>${p.communityName}</td></tr>`
			if (p.status) html += `<tr><td>Status</td><td>${p.status}</td></tr>`
			if (prices.min && prices.max)
				html += `<tr><td>Price Range</td><td>${numeral(prices.min).format("$0ak")} - ${numeral(
					prices.max
				).format("$0ak")}</td></tr>`
			if (p.lot_types) html += `<tr><td>Lot Types</td><td>${JSON.parse(p.lot_types).join(", ")}</td></tr>`
			if (p.builders_names)
				html += `<tr><td>Builders</td><td>${JSON.parse(p.builders_names).join(", ")}</td></tr>`
			if (p.annual_starts) html += `<tr><td>Annual Starts</td><td>${p.annual_starts}</td></tr>`
			if (p.futures) html += `<tr><td>Futures</td><td>${p.futures}</td></tr>`
			if (p.vdl) html += `<tr><td>VDL</td><td>${p.vdl}</td></tr>`
			if (p.complete_vacant) html += `<tr><td>Complete Vacant</td><td>${p.complete_vacant}</td></tr>`
			html += `</table>`

			html += `</div>`
			// Ensure that if the map is zoomed out such that multiple
			// copies of the feature are visible, the popup appears
			// over the copy being pointed to.
			while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
				coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
			}

			// Populate the popup and set its coordinates
			// based on the feature found.
			popup.setLngLat(coordinates).setHTML(html).addTo(map)
		})

		map.on("mouseleave", "dots", () => {
			map.getCanvas().style.cursor = ""
			popup.remove()
		})
		try {
			if (geojson.features.length > 0) {
				// Filter out features with null coordinates
				let filteredFeatures = geojson.features.filter((feature) => {
					if (!feature.geometry?.coordinates) return false
					const [lng, lat] = feature.geometry.coordinates
					return lng != null && lat != null && !isNaN(lng) && !isNaN(lat)
				})
				geojson.features = filteredFeatures

				map.fitBounds(turf.bbox(geojson), {
					padding: 100,
					duration: 2000,
					maxZoom: 15,
				})
			}
		} catch (e) {}
	}

	const BackTo = () => {
		if (viewing.name === "subdivisions") return null
		if (viewing.name === "subdivision")
			return (
				<button
					onClick={() => setViewing({ name: "subdivisions" })}
					className="px-2 py-1 bg-darkBlue text-white rounded-md text-xs inline-block cursor-pointer hover:bg-primary/80 transition duration-300 mb-3 ml-2">
					Back to Neighborhoods
				</button>
			)
	}

	const updateLayerOpacity = () => {
		if (map && layer) {
			const layerId = {
				zip: "zips",
				county: "counties",
				market: "markets",
				submarket: "submarkets",
				district: "districts",
				attendance_elementary: "attendance_elementary",
				attendance_middle: "attendance_middle",
				attendance_high: "attendance_high",
			}[layer]
			if (layerId && map.getLayer(layerId)) {
				map.setPaintProperty(layerId, "fill-opacity", [
					"case",
					["in", ["to-number", ["get", idKey[layerId]]], ["literal", selectedLayerRef.current || []]],
					0.6,
					0.3,
				])
			}
		}
	}

	const clearLayerRef = () => {
		setGeographicSelected(false) // this is sort of jacked into place, but I think it works without having to pass another fn to the selects.
		if (selectedLayerRef.current) {
			selectedLayerRef.current = null
			updateLayerOpacity()
			formikRef.current.handleSubmit()
		}
	}

	const resetFilters = (f) => {
		setSelections(_.cloneDeep(fields))
		if (f) f.resetForm()
		selectedLayerRef.current = null
		setLayer("")
		removeAllLayers()
		setRadiusMarker(false)
		setGeographicSelected(false)
		setSelectPolygon(false)
		removeMarkers()
		drawRef.current.deleteAll()
		setAreaSaving(false)
		if (radiusMarker) {
			radiusMarker.remove()
			if (map.getLayer("circle-layer")) map.removeLayer("circle-layer")
			if (map.getSource("circle-source")) map.removeSource("circle-source")
		}
		setViewing({ name: "subdivisions" })
		if (map) {
			flyToCenter()
			removeMarkers()
		}
	}

	const handleZips = async () => {
		setLoading(true)
		if (!map) return
		//? Have to pull the zips data, fresh load or cache expired
		if (!zipsData) {
			let resp = await getZips({ action: "pullGeojson", type: "zips", tags: [] })
			if (resp?.data?.features && map) {
				map.addSource("zips", {
					type: "geojson",
					data: resp.data,
				})
				let zipsCenter = buildCenters(resp.data)
				map.addSource("zips_centers", {
					type: "geojson",
					data: zipsCenter,
				})
			}
		} else {
			if (!map.getSource("zips")) {
				map.addSource("zips", {
					type: "geojson",
					data: zipsData,
				})
			}
			if (!map.getSource("zips_centers")) {
				let zipsCenter = buildCenters(zipsData)
				map.addSource("zips_centers", {
					type: "geojson",
					data: zipsCenter,
				})
			}
		}
		if (map.getLayer("zips")) map.removeLayer("zips")
		map.addLayer({
			id: "zips",
			type: "fill",
			source: "zips",
			slot: "bottom",
			paint: {
				"fill-color": "#0080ff",
				"fill-opacity": ["case", ["in", ["get", "Zip_Code"], ["literal", selectedLayerRef.current]], 0.6, 0.3],
			},
		})
		if (map.getLayer("zips_outline")) map.removeLayer("zips_outline")
		map.addLayer({
			id: "zips_outline",
			type: "line",
			source: "zips",
			layout: {},
			paint: {
				"line-color": "#000",
				"line-width": 2,
				"line-opacity": 0.5,
			},
		})
		if (map.getLayer("zips_labels")) map.removeLayer("zips_labels")
		map.addLayer({
			id: "zips_labels",
			type: "symbol",
			source: "zips_centers",
			slot: "bottom",
			//minzoom: 17,
			layout: {
				"text-field": ["get", "Zip_Code"],
				"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
				//"text-offset": [0, 1.25],
				//"text-anchor": "top",
				"text-size": 13,
			},
			paint: {
				"text-color": "#222",
				//"text-halo-blur": 4,
				"text-halo-color": "white",
				"text-halo-width": 10,
			},
		})
		if (selections.zips && selections.zips.length > 0) {
			selectedLayerRef.current = selections.zips
		} else selectedLayerRef.current = null
		setLoading(false)
	}

	const handleCounties = async () => {
		setLoading(true)
		//? Have to pull the counties data, fresh load or cache expired
		if (!countiesData || !map.getSource("counties")) {
			let resp = await getCounties({ action: "pullGeojson", type: "counties", tags: [] })
			if (resp?.data?.features && map) {
				map.addSource("counties", {
					type: "geojson",
					data: resp.data,
				})
				let countiesCenter = buildCenters(resp.data)
				map.addSource("counties_centers", {
					type: "geojson",
					data: countiesCenter,
				})
			}
		}
		map.addLayer({
			id: "counties",
			type: "fill",
			source: "counties",
			//slot: "bottom",
			paint: {
				"fill-color": "#0080ff",
				"fill-opacity": [
					"case",
					["in", ["to-number", ["get", "id"]], ["literal", selectedLayerRef.current]],
					0.6,
					0.3,
				],
			},
		})
		map.addLayer({
			id: "county_outline",
			type: "line",
			source: "counties",
			layout: {},
			paint: {
				"line-color": "#000",
				"line-width": 2,
				"line-opacity": 0.5,
			},
		})
		map.addLayer({
			id: "counties_labels",
			type: "symbol",
			source: "counties_centers",
			//slot: "bottom",
			//minzoom: 17,
			layout: {
				"text-field": ["get", "CNTY_NM"],
				"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
				//"text-offset": [0, 1.25],
				//"text-anchor": "top",
				"text-size": 13,
			},
			paint: {
				"text-color": "#222",
				//"text-halo-blur": 4,
				"text-halo-color": "white",
				"text-halo-width": 10,
			},
		})
		if (selections.counties && selections.counties.length > 0) {
			selectedLayerRef.current = selections.counties
		} else selectedLayerRef.current = null
		setLoading(false)
	}

	const handleDistricts = async () => {
		setLoading(true)
		if (!districtsData || !map.getSource("districts")) {
			let resp = await getDistricts({ action: "pullGeojson", type: "districts", tags: [] })
			if (resp?.data?.features && map) {
				map.addSource("districts", {
					type: "geojson",
					data: resp.data,
				})
				let districtsCenter = buildCenters(resp.data)
				map.addSource("districts_centers", {
					type: "geojson",
					data: districtsCenter,
				})
			}
		}
		map.addLayer({
			id: "districts",
			type: "fill",
			source: "districts",
			slot: "bottom",
			paint: {
				"fill-color": "#0080ff",
				"fill-opacity": [
					"case",
					["in", ["to-number", ["get", "id"]], ["literal", selectedLayerRef.current]],
					0.6,
					0.3,
				],
			},
		})
		map.addLayer({
			id: "districts_outline",
			type: "line",
			source: "districts",
			layout: {},
			paint: {
				"line-color": "#000",
				"line-width": 2,
				"line-opacity": 0.5,
			},
		})
		map.addLayer({
			id: "districts_labels",
			type: "symbol",
			source: "districts_centers",
			slot: "bottom",
			//minzoom: 17,
			layout: {
				"text-field": ["get", "OBJ_NAME"],
				"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
				//"text-offset": [0, 1.25],
				//"text-anchor": "top",
				"text-size": 13,
			},
			paint: {
				"text-color": "#222",
				//"text-halo-blur": 4,
				"text-halo-color": "white",
				"text-halo-width": 10,
			},
		})
		if (selections.districts && selections.districts.length > 0) {
			selectedLayerRef.current = selections.districts
		} else selectedLayerRef.current = null
		setLoading(false)
	}

	const handleMuds = async () => {
		setLoading(true)
		if (!mudsData || !map.getSource("muds")) {
			let resp = await getMuds({ action: "pullGeojson", type: "muds", tags: [] })
			if (resp?.data?.features && map) {
				map.addSource("muds", {
					type: "geojson",
					data: resp.data,
				})
				let mudsCenter = buildCenters(resp.data)
				map.addSource("muds_centers", {
					type: "geojson",
					data: mudsCenter,
				})
			}
		}
		map.addLayer({
			id: "muds",
			type: "fill",
			source: "muds",
			slot: "bottom",
			paint: {
				"fill-color": "#0080ff",
				"fill-opacity": [
					"case",
					["in", ["to-number", ["get", "id"]], ["literal", selectedLayerRef.current]],
					0.6,
					0.3,
				],
			},
		})
		map.addLayer({
			id: "muds_outline",
			type: "line",
			source: "muds",
			layout: {},
			paint: {
				"line-color": "#000",
				"line-width": 2,
				"line-opacity": 0.5,
			},
		})
		map.addLayer({
			id: "muds_labels",
			type: "symbol",
			source: "muds_centers",
			slot: "bottom",
			//minzoom: 17,
			layout: {
				"text-field": ["get", "NAME"],
				"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
				//"text-offset": [0, 1.25],
				//"text-anchor": "top",
				"text-size": 13,
			},
			paint: {
				"text-color": "#222",
				//"text-halo-blur": 4,
				"text-halo-color": "white",
				"text-halo-width": 10,
			},
		})
		if (selections.muds && selections.muds.length > 0) {
			selectedLayerRef.current = selections.muds
		} else selectedLayerRef.current = null
		setLoading(false)
	}

	const handleSchools = async () => {
		setLoading(true)
		//? Have to pull the counties data, fresh load or cache expired
		if (!schoolsData || !map.getSource("schools")) {
			let resp = await getSchools({ action: "pullGeojson", type: "schools", tags: [] })
			if (resp?.data?.features && map) {
				map.addSource("schools", {
					type: "geojson",
					data: resp.data,
				})
			}
		}
		let translatedIDs = []
		if (layerRef.current === "schools" && data?.schools) {
			_.forEach(data.schools, (s) => {
				if (selections.schools.includes(s.id)) {
					translatedIDs.push(s.external_name)
				}
			})
		}
		const filter =
			layerRef.current === "schools"
				? ["in", ["get", "OBJ_NAME"], ["literal", translatedIDs]] // Show schools in the array
				: ["!=", ["get", "OBJ_NAME"], ""] // Show all schools

		map.addLayer({
			id: "schools_text",
			type: "symbol",
			source: "schools",
			filter: filter,
			layout: {
				"text-field": ["get", "OBJ_NAME"],
				"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
				"text-offset": [0, 1.25],
				"text-size": 9,
				"text-anchor": "top",
			},
		})
		map.addLayer({
			id: "schools_circles",
			type: "circle",
			source: "schools",
			filter: filter,
			paint: {
				"circle-radius": 5,
				"circle-color": "#c066ed",
			},
		})
		setLoading(false)
	}

	const handleAttendanceZone = async (type) => {
		setLoading(true)

		if (!attendanceZones || !map.getSource(`attendance_${type}`)) {
			let resp = await getAttendanceZones({ action: "pullGeojson", type: `attendance_${type}`, tags: [] })
			if (resp?.data?.features && map) {
				map.addSource(`attendance_${type}`, {
					type: "geojson",
					data: resp.data,
				})
				let attendance_elementaryCenter = buildCenters(resp.data)
				map.addSource(`attendance_${type}_centers`, {
					type: "geojson",
					data: attendance_elementaryCenter,
				})
			}
		}
		map.addLayer({
			id: `attendance_${type}`,
			type: "fill",
			source: `attendance_${type}`,
			slot: "bottom",
			paint: {
				"fill-color": "#0080ff",
				"fill-opacity": [
					"case",
					["in", ["to-number", ["get", "id"]], ["literal", selectedLayerRef.current]],
					0.6,
					0.3,
				],
			},
		})
		map.addLayer({
			id: `attendance_${type}_outline`,
			type: "line",
			source: `attendance_${type}`,
			layout: {},
			paint: {
				"line-color": "#000",
				"line-width": 2,
				"line-opacity": 0.5,
			},
		})
		map.addLayer({
			id: `attendance_${type}_labels`,
			type: "symbol",
			source: `attendance_${type}_centers`,
			slot: "bottom",
			//minzoom: 17,
			layout: {
				"text-field": ["get", "name"],
				"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
				//"text-offset": [0, 1.25],
				//"text-anchor": "top",
				"text-size": 13,
			},
			paint: {
				"text-color": "#222",
				//"text-halo-blur": 4,
				"text-halo-color": "white",
				"text-halo-width": 10,
			},
		})
		if (selections.schools && selections.schools.length > 0) {
			selectedLayerRef.current = selections.schools
		}
		setLoading(false)
	}

	const handleEmptyGeographics = (v) => {
		if (!v) {
			setGeographicSelected(false)
			selectedLayerRef.current = null
			removeAllLayers()
			formikRef.current.handleSubmit()
		}
		/* let values = f.values
		// When all options from geo are emptied, it should free up all the geo selects and remove any layers from the map
		let checking = ["counties", "markets", "submarkets", "zips", "districts", "schools"]
		let wipeIt = false
		_.forEach(checking, (c) => {
			if (!values[c] || values[c].length === 0) wipeIt = true
		})
		if (wipeIt) {
			console.log("wiping it")
			selectedLayerRef.current = null
			removeAllLayers()
			f.handleSubmit()
			setGeographicSelected(false)
		} */
	}

	const handleMarkets = async () => {
		setLoading(true)
		//? Have to pull the markets data, fresh load or cache expired
		if (!marketsData || !map.getSource("markets")) {
			let resp = await getMarkets({ action: "pullGeojson", type: "markets", tags: [] })
			if (resp?.data?.features && map) {
				map.addSource("markets", {
					type: "geojson",
					data: resp.data,
				})
				let marketsCenter = buildCenters(resp.data)
				map.addSource("markets_centers", {
					type: "geojson",
					data: marketsCenter,
				})
			}
		}
		map.addLayer({
			id: "markets",
			type: "fill",
			source: "markets",
			slot: "bottom",
			paint: {
				"fill-color": "#0080ff",
				"fill-opacity": [
					"case",
					["in", ["to-number", ["get", "id"]], ["literal", selectedLayerRef.current]],
					0.6,
					0.3,
				],
			},
		})
		map.addLayer({
			id: "county_outline",
			type: "line",
			source: "markets",
			layout: {},
			paint: {
				"line-color": "#000",
				"line-width": 2,
				"line-opacity": 0.5,
			},
		})
		map.addLayer({
			id: "markets_labels",
			type: "symbol",
			source: "markets_centers",
			slot: "bottom",
			//minzoom: 17,
			layout: {
				"text-field": ["get", "CBAS_Mkt"],
				"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
				//"text-offset": [0, 1.25],
				//"text-anchor": "top",
				"text-size": 13,
			},
			paint: {
				"text-color": "#222",
				//"text-halo-blur": 4,
				"text-halo-color": "white",
				"text-halo-width": 10,
			},
		})
		if (selections.markets && selections.markets.length > 0) {
			selectedLayerRef.current = selections.markets
		} else selectedLayerRef.current = null
		setLoading(false)
	}

	const handleSubmarkets = async () => {
		setLoading(true)
		//? Have to pull the markets data, fresh load or cache expired
		if (!submarketsData || !map.getSource("submarkets")) {
			let resp = await getSubmarkets({ action: "pullGeojson", type: "submarkets", tags: [] })
			if (resp?.data?.features && map) {
				map.addSource("submarkets", {
					type: "geojson",
					data: resp.data,
				})
				let submarketsCenter = buildCenters(resp.data)
				map.addSource("submarkets_centers", {
					type: "geojson",
					data: submarketsCenter,
				})
			}
		}
		map.addLayer({
			id: "submarkets",
			type: "fill",
			source: "submarkets",
			slot: "bottom",
			paint: {
				"fill-color": "#0080ff",
				"fill-opacity": [
					"case",
					["in", ["to-number", ["get", "id"]], ["literal", selectedLayerRef.current]],
					0.6,
					0.3,
				],
			},
		})
		if (!map.getLayer("submarkets_outline")) {
			map.addLayer({
				id: "submarkets_outline",
				type: "line",
				source: "submarkets",
				layout: {},
				paint: {
					"line-color": "#000",
					"line-width": 2,
					"line-opacity": 0.5,
				},
			})
		}
		if (!map.getLayer("submarkets_labels")) {
			map.addLayer({
				id: "submarkets_labels",
				type: "symbol",
				source: "submarkets_centers",
				slot: "bottom",
				//minzoom: 17,
				layout: {
					"text-field": ["get", "CBAS_SubMk"],
					"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
					//"text-offset": [0, 1.25],
					//"text-anchor": "top",
					"text-size": 13,
				},
				paint: {
					"text-color": "#222",
					//"text-halo-blur": 4,
					"text-halo-color": "white",
					"text-halo-width": 10,
				},
			})
		}

		if (selections.submarkets && selections.submarkets.length > 0) {
			selectedLayerRef.current = selections.submarkets
		} else selectedLayerRef.current = null
		setLoading(false)
	}

	const handleHeatStarts = async () => {
		setLoading(true)
		if (!map) return
		let store = allLatest.data.entries
		const geo = {
			type: "FeatureCollection",
			crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
			features: [],
		}
		/* if (!allLatest) {
			store = await getAllLatest({ action: "getAllLatest", tags: ['allLatest'] })
			store = store.data.entries
		} */
		_.forEach(store, (v) => {
			geo.features.push({
				type: "Feature",
				properties: {
					value: v.starts,
				},
				geometry: {
					type: "Point",
					coordinates: [v.lng, v.lat],
				},
			})
		})

		if (!map.getSource("heat_starts")) {
			map.addSource("heat_starts", {
				type: "geojson",
				data: geo,
			})
		}
		map.addLayer(
			{
				id: "heat_starts",
				type: "heatmap",
				source: "heat_starts",
				maxzoom: 18,
				paint: {
					// Increase the heatmap weight based on frequency and property magnitude
					"heatmap-weight": ["interpolate", ["linear"], ["get", "value"], 0, 0, 6, 1],
					// Increase the heatmap color weight weight by zoom level
					// heatmap-intensity is a multiplier on top of heatmap-weight
					"heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 8, 1.5, 11, 2, 14, 2.5],
					// Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
					// Begin color ramp at 0-stop with a 0-transparancy color
					// to create a blur-like effect.
					"heatmap-color": [
						"interpolate",
						["linear"],
						["heatmap-density"],
						0,
						"rgba(33,102,172,0)",
						0.2,
						"rgb(103,169,207)",
						0.4,
						"rgb(209,229,240)",
						0.6,
						"rgb(253,219,199)",
						0.7,
						"rgb(239,138,98)",
						1,
						"rgb(178,24,43)",
					],
					// Adjust the heatmap radius by zoom level
					"heatmap-radius": ["interpolate", ["linear"], ["get", "value"], 0, 40, 20, 500],
					// Transition from heatmap to circle layer by zoom level
					"heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 1, 0.1, 7, 0.5],
				},
			},
			"waterway-label"
		)
		setLoading(false)
	}

	const handleHeatVDLs = async () => {
		setLoading(true)
		if (!map) return
		let store = allLatest.data.entries
		const geo = {
			type: "FeatureCollection",
			crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
			features: [],
		}
		/* if (!allLatest) {
			store = await getAllLatest({ action: "getAllLatest", tags: ['allLatest'] })
			store = store.data.entries
		} */
		_.forEach(store, (v) => {
			geo.features.push({
				type: "Feature",
				properties: {
					value: v.vdls,
				},
				geometry: {
					type: "Point",
					coordinates: [v.lng, v.lat],
				},
			})
		})

		if (!map.getSource("heat_vdls")) {
			map.addSource("heat_vdls", {
				type: "geojson",
				data: geo,
			})
		}
		map.addLayer(
			{
				id: "heat_vdls",
				type: "heatmap",
				source: "heat_vdls",
				maxzoom: 18,
				paint: {
					// Increase the heatmap weight based on frequency and property magnitude
					"heatmap-weight": ["interpolate", ["linear"], ["get", "value"], 0, 0, 10, 0.5, 20, 0.75, 50, 1],
					// Increase the heatmap color weight weight by zoom level
					// heatmap-intensity is a multiplier on top of heatmap-weight
					// at zoom 0, intensity will be 1, then at zoom 8, intensity will be 1.5 etc
					"heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 0.1, 8, 0.2, 11, 2, 14, 2.5],
					// Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
					// Begin color ramp at 0-stop with a 0-transparancy color
					// to create a blur-like effect.
					"heatmap-color": [
						"interpolate",
						["linear"],
						["heatmap-density"],
						0,
						"rgba(33,102,172,0)",
						0.2,
						"rgb(103,169,207)",
						0.4,
						"rgb(209,229,240)",
						0.6,
						"rgb(253,219,199)",
						0.7,
						"rgb(239,138,98)",
						1,
						"rgb(178,24,43)",
					],
					// Adjust the heatmap radius by zoom level
					"heatmap-radius": ["interpolate", ["linear"], ["get", "value"], 0, 40, 20, 500],
					// Transition from heatmap to circle layer by zoom level
					"heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 1, 0.1, 7, 0.5],
				},
			},
			"waterway-label"
		)
		setLoading(false)
	}

	const buildCenters = (data) => {
		let d = _.cloneDeep(data)
		_.forEach(d.features, (v) => {
			if (_.get(v, "geometry.type") !== "Polygon") return
			let points = turf.points(v.geometry.coordinates[0])
			const center = turf.center(points).geometry.coordinates
			v.geometry = {
				type: "Point",
				coordinates: center,
			}
			//return v
		})

		return d
	}

	const changeMapStyle = (style) => {
		if (map) {
			setMapStyle(style)
			map.setStyle(style)
			map.once("styledata", () => {
				layerChange()
				mapOutSubdivisions({ dontFly: true })
				if (radiusMarker) {
					drawRadiusCircle(radiusMarker)
				}
			})
		}
	}

	const flyToCenter = () => {
		if (map) map.flyTo({ center: [-95.36723795379703, 29.7627587122713], zoom: 10 })
	}

	const modifyArea = async (action) => {
		if (action === "save") {
			let area = _.cloneDeep(selections.area)
			//_.unset(area, "features[0].id")
			let feature = area.features[0]
			let resp = await saveArea({
				feature: {
					feature,
				},
				name: areaName,
				action: "saveArea",
				tags: ["payload"],
			})
			if (resp?.data?.status === 200) {
				toast.success(resp.data?.message)
			}
		} else if (action === "delete") {
			let area = _.cloneDeep(selections.area)
			let feature = area.features[0]
			let resp = await saveArea({
				feature: {
					feature,
				},
				name: areaName,
				action: "deleteArea",
				tags: ["payload"],
			})
			if (resp?.data?.status === 200) {
				toast.success(resp.data?.message)
				drawRef.current.deleteAll()
				setAreaSaving(false)
			}
		}
	}

	const updateRadius = (newValue) => {
		if (!newValue) newValue = 1
		setRadius(newValue)
		radiusRef.current = newValue
	}

	const logout = () => {
		localStorage.removeItem("user")
		navigate("/login")
	}

	const handleCheckbox = (index) => {
		setEntries((prevEntries) => {
			const updatedEntries = [...prevEntries]
			updatedEntries[index] = {
				...updatedEntries[index],
				isChecked: !updatedEntries[index].isChecked,
			}
			return updatedEntries
		})
	}

	const buildEntries = () => {
		if (viewing?.name === "subdivisions") {
			let rows = _.cloneDeep(d?.data?.entries)
			_.forEach(rows, (v) => {
				v.isChecked = true
			})
			if (rows) {
				rows = _.sortBy(rows, ["name"])
			}
			let r = _.map(rows, (entry, k) => {
				let name = (
					<a
						className="text-darkBlue cursor-pointer font-bold"
						onClick={() => {
							setViewing({ name: "subdivision", id: entry.id })
						}}>
						{entry.name}
					</a>
				)
				return {
					...entry,
					id: k + 1,
					subID: entry.id,
					name,
				}
			})
			setEntries(r)
		} else if (viewing?.name === "subdivision") {
			let rows = d?.data?.entries
			if (rows) {
				rows = _.sortBy(rows, ["name"])
			}
			let store = []
			let filtered = _.filter(rows, (entry) => entry.id === viewing?.id)
			_.forEach(filtered, (sub, k) => {
				_.forEach(sub.sections, (sec) => {
					let f = {
						id: sec.id,
						section: sec.name,
						subdivisionName: sub.name,
						price_range: {
							min: 0,
							max: 0,
						},
						sqft_range: {
							min: 0,
							max: 0,
						},
						annual_starts: 0,
						annual_closings: 0,
						futures: 0,
						vdls: 0,
						complete_vacant: 0,
						models: 0,
						under_construction: 0,
						starts: 0,
						closings: 0,
						quarter_start: sec.quarter_start ? moment.unix(sec.quarter_start).format("[Q]Q YY") : "",
						quarter_complete: sec.quarter_complete
							? moment.unix(sec.quarter_complete).format("[Q]Q YY")
							: "",
					}
					let latestEntries = helpers.latestEntriesInSection(sec.entries)
					//
					_.forEach(latestEntries, (entry, k) => {
						if (!f.price_range.min || entry.price_min < f.price_range.min)
							f.price_range.min = entry.price_min
						if (entry.price_max > f.price_range.max) f.price_range.max = entry.price_max
						if (!f.sqft_range.min || entry.sqft_min < f.sqft_range.min) f.sqft_range.min = entry.sqft_min
						if (entry.sqft_max > f.sqft_range.max) f.sqft_range.max = entry.sqft_max
					})
					let price_range = `${numeral(f.price_range.min).format("$0,0")} - ${numeral(
						f.price_range.max
					).format("$0,0")}`
					let sqft_range = `${numeral(f.sqft_range.min).format("0,0")} - ${numeral(f.sqft_range.max).format(
						"0,0"
					)}`
					f.price_range_text = price_range
					f.sqft_range_text = sqft_range
					_.forEach(latestEntries, (entry, k) => {
						if (entry.futures) f.futures += entry.futures
						if (entry.vdl) f.vdls += entry.vdl
						if (entry.complete_vacant) f.complete_vacant += entry.complete_vacant
						if (entry.under_construction) f.under_construction += entry.under_construction
						if (entry.models) f.models += entry.models
						if (entry.starts) f.starts += entry.starts
						if (entry.closings) f.closings += entry.closings
					})
					//? figure out annual/ttm info
					let ttmEntries = helpers.ttmEntriesInSection(sec.entries)
					_.forEach(ttmEntries, (entry, k) => {
						f.annual_starts += entry.starts
						f.annual_closings += entry.closings
					})
					store.push(f)
				})
			})
			setEntries(store)
		}
	}

	const ActiveFilters = () => {
		let list = []
		let classes = `flex items-center py-1 px-1 border border-dashed border-gray-400 rounded-lg text-[10px] hover:bg-gray-100 cursor-pointer child:mr-1 mr-2`
		let close = (
			<span className="w-3">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
					<path
						className="fill-red-500"
						d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm97.9-320l-17 17-47 47 47 47 17 17L320 353.9l-17-17-47-47-47 47-17 17L158.1 320l17-17 47-47-47-47-17-17L192 158.1l17 17 47 47 47-47 17-17L353.9 192z"
					/>
				</svg>
			</span>
		)

		_.forEach(selections, (v, k) => {
			if (typeof v === "object" && v?.min) {
				// Min/max logic
				let name = selectionTranslations[k] || k
				list.push(
					<div
						className={classes}
						onClick={() => {
							let sel = _.cloneDeep(selections)
							sel[k] = { min: null, max: null }
							setSelections(sel)
							formikRef.current.setFieldValue(k, { min: null, max: null })
						}}>
						{close}
						<div className="font-bold">{name}</div>
						<div>
							{numeral(v.min).format("0,0")} - {numeral(v.max).format("0,0")}
						</div>
					</div>
				)
			} else if (k === "area" && v?.features?.length > 0) {
				let polyName = ""
				try {
					let lookup = _.find(data.user.areas.features, { id: v.features[0].id })
					if (lookup) polyName = lookup.properties.name
				} catch (e) {
					console.log(e)
				}
				list.push(
					<div
						className={classes}
						onClick={() => {
							setSelectPolygon(false)
							drawRef.current.deleteAll()
							removeMarkers()
							flyToCenter()
							setAreaSaving(false)
							setSelections(_.cloneDeep(fields))
							return
						}}>
						{close}
						<div className="font-bold">Polygon: {polyName} </div>
					</div>
				)
			} else if (Array.isArray(v) && v.length > 0) {
				let n = selectionTranslations[k] || k
				_.forEach(v, (val) => {
					let name = _.find(data[k], { id: val })?.name
					list.push(
						<div
							className={classes}
							onClick={() => {
								let sel = _.cloneDeep(selections)
								sel[k] = _.filter(sel[k], (s) => s !== val)
								setSelections(sel)
								formikRef.current.setFieldValue(
									k,
									_.filter(formikRef.current.values[k], (s) => s !== val)
								)
								if (sel[k].length === 0) {
									setGeographicSelected(false)
									selectedLayerRef.current = null
									removeAllLayers()
								}
							}}>
							{close}
							<div className="font-bold">{n}</div>
							<div>{name}</div>
						</div>
					)
				})
			}
		})
		return <div className="flex pl-2 pb-2">{list}</div>
	}
	//let entries = buildEntries()

	//
	return (
		<div className="App h-full">
			{loading ? (
				<div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-80 z-[5000] flex items-center justify-center">
					<GridLoader
						color={"#005C90"}
						loading={loading}
						//cssOverride={override}
						size={20}
						aria-label="Loading Spinner"
						data-testid="loader"
					/>
				</div>
			) : (
				""
			)}

			{error ? (
				<div className="text-center pt-24">System down for maintenance. Please try again shortly.</div>
			) : null}
			<div className="h-[100vh] flex">
				<div
					className="filters relative"
					style={{
						width: sideDrawerVisible ? "20%" : "4%",
					}}>
					<div
						className={classNames(``, {
							"opacity-0": !sideDrawerVisible,
						})}>
						<div className="flex justify-between items-start py-4 px-3">
							<img
								src="https://cbas-primary.s3.us-east-2.amazonaws.com/logo-color.svg"
								className="w-7/12"
								alt="Logo"
							/>
						</div>
						<div className="w-full flex text-xs text-center child:flex-1 child:py-2 child:cursor-pointer border-t-2 border-b-2 border-darkBlue font-bold">
							<div
								onClick={() => setFilterListShowing("subdivisions")}
								className={classNames("", {
									"bg-darkBlue text-white": filterListShowing === "subdivisions",
								})}>
								Neighborhoods
							</div>
							<div
								onClick={() => setFilterListShowing("geographics")}
								className={classNames("", {
									"bg-darkBlue text-white": filterListShowing === "geographics",
								})}>
								Geographics
							</div>
						</div>
						{isSuccess ? (
							<Formik
								initialValues={_.cloneDeep(fields)}
								innerRef={formikRef}
								onSubmit={async (values, { resetForm, setSubmitting }) => {
									// Check if area is selected
									if (selections.area?.features?.length > 0) {
										values.area = selections.area
									}
									setSelections(values)
									setViewing({ name: "subdivisions" })
								}}>
								{(f) => {
									if (filterListShowing === "subdivisions") {
										return (
											<>
												<Form>
													{/*  */}
													<ul className="filterList">
														<li>
															<label>MPC</label>
															<Select
																name="communities"
																isMulti
																value={f.values.communities}
																onChange={f.setFieldValue}
																options={helpers.buildPayloadOptions(data.communities)}
																onBlur={f.setFieldTouched}
															/>
														</li>
														<li>
															<label>Neighborhood</label>
															<Select
																name="subdivisions"
																isMulti
																value={f.values.subdivisions}
																onChange={f.setFieldValue}
																options={helpers.buildNeighborhoods(data.subdivisions)}
																onBlur={f.setFieldTouched}
															/>
														</li>
														<li>
															<label>Builders</label>
															<Select
																name="builders"
																isMulti
																value={f.values.builders}
																onChange={f.setFieldValue}
																options={helpers.buildPayloadOptions(data.builders)}
																onBlur={f.setFieldTouched}
															/>
														</li>
														<li>
															<label>Price</label>
															<div className="flex">
																<div className="flex-1 pr-1">
																	<NumberField
																		name="prices.min"
																		type="text"
																		className="form__input"
																		onChange={f.setFieldValue}
																		onBlur={f.setFieldTouched}
																		touched={f.touched.prices?.min}
																		error={f.errors.prices?.min}
																		value={f.values.prices?.min}
																		thousandSeparator={true}
																		numeric={true}
																		prefix="$"
																	/>
																</div>
																<div className="flex-1 pl-1">
																	<NumberField
																		name="prices.max"
																		type="text"
																		className="form__input"
																		onChange={f.setFieldValue}
																		onBlur={f.setFieldTouched}
																		touched={f.touched.prices?.max}
																		error={f.errors.prices?.max}
																		value={f.values.prices?.max}
																		thousandSeparator={true}
																		numeric={true}
																		prefix="$"
																	/>
																</div>
															</div>
														</li>
														<li>
															<label>Frontage</label>
															<div className="flex">
																<div className="flex-1 pr-1">
																	<NumberField
																		name="lot_type_range.min"
																		type="text"
																		className="form__input"
																		onChange={f.setFieldValue}
																		onBlur={f.setFieldTouched}
																		touched={f.touched.lot_type_range?.min}
																		error={f.errors.lot_type_range?.min}
																		value={f.values.lot_type_range?.min}
																		thousandSeparator={true}
																		numeric={true}
																	/>
																</div>
																<div className="flex-1 pl-1">
																	<NumberField
																		name="lot_type_range.max"
																		type="text"
																		className="form__input"
																		onChange={f.setFieldValue}
																		onBlur={f.setFieldTouched}
																		touched={f.touched.lot_type_range?.max}
																		error={f.errors.lot_type_range?.max}
																		value={f.values.lot_type_range?.max}
																		thousandSeparator={true}
																		numeric={true}
																	/>
																</div>
															</div>
														</li>
														<li>
															<label>Futures</label>
															<div className="flex">
																<div className="flex-1 pr-1">
																	<NumberField
																		name="futures.min"
																		type="text"
																		className="form__input"
																		onChange={f.setFieldValue}
																		onBlur={f.setFieldTouched}
																		touched={f.touched.futures?.min}
																		error={f.errors.futures?.min}
																		value={f.values.futures?.min}
																		thousandSeparator={true}
																		numeric={true}
																	/>
																</div>
																<div className="flex-1 pl-1">
																	<NumberField
																		name="futures.max"
																		type="text"
																		className="form__input"
																		onChange={f.setFieldValue}
																		onBlur={f.setFieldTouched}
																		touched={f.touched.futures?.max}
																		error={f.errors.futures?.max}
																		value={f.values.futures?.max}
																		thousandSeparator={true}
																		numeric={true}
																	/>
																</div>
															</div>
														</li>
														<li>
															<label>VDL</label>
															<div className="flex">
																<div className="flex-1 pr-1">
																	<NumberField
																		name="vdls.min"
																		type="text"
																		className="form__input"
																		onChange={f.setFieldValue}
																		onBlur={f.setFieldTouched}
																		touched={f.touched.vdls?.min}
																		error={f.errors.vdls?.min}
																		value={f.values.vdls?.min}
																		thousandSeparator={true}
																		numeric={true}
																	/>
																</div>
																<div className="flex-1 pl-1">
																	<NumberField
																		name="vdls.max"
																		type="text"
																		className="form__input"
																		onChange={f.setFieldValue}
																		onBlur={f.setFieldTouched}
																		touched={f.touched.vdls?.max}
																		error={f.errors.vdls?.max}
																		value={f.values.vdls?.max}
																		thousandSeparator={true}
																		numeric={true}
																	/>
																</div>
															</div>
														</li>
														<li>
															<label>Annual Starts</label>
															<div className="flex">
																<div className="flex-1 pr-1">
																	<NumberField
																		name="annual_starts.min"
																		type="text"
																		className="form__input"
																		onChange={f.setFieldValue}
																		onBlur={f.setFieldTouched}
																		touched={f.touched.annual_starts?.min}
																		error={f.errors.annual_starts?.min}
																		value={f.values.annual_starts?.min}
																		thousandSeparator={true}
																		numeric={true}
																	/>
																</div>
																<div className="flex-1 pl-1">
																	<NumberField
																		name="annual_starts.max"
																		type="text"
																		className="form__input"
																		onChange={f.setFieldValue}
																		onBlur={f.setFieldTouched}
																		touched={f.touched.annual_starts?.max}
																		error={f.errors.annual_starts?.max}
																		value={f.values.annual_starts?.max}
																		thousandSeparator={true}
																		numeric={true}
																	/>
																</div>
															</div>
														</li>
														<li>
															<label>Annual Closings</label>
															<div className="flex">
																<div className="flex-1 pr-1">
																	<NumberField
																		name="annual_closings.min"
																		type="text"
																		className="form__input"
																		onChange={f.setFieldValue}
																		onBlur={f.setFieldTouched}
																		touched={f.touched.annual_closings?.min}
																		error={f.errors.annual_closings?.min}
																		value={f.values.annual_closings?.min}
																		thousandSeparator={true}
																		numeric={true}
																	/>
																</div>
																<div className="flex-1 pl-1">
																	<NumberField
																		name="annual_closings.max"
																		type="text"
																		className="form__input"
																		onChange={f.setFieldValue}
																		onBlur={f.setFieldTouched}
																		touched={f.touched.annual_closings?.max}
																		error={f.errors.annual_closings?.max}
																		value={f.values.annual_closings?.max}
																		thousandSeparator={true}
																		numeric={true}
																	/>
																</div>
															</div>
														</li>
													</ul>
													<button
														type="submit"
														disabled={f.isSubmitting}
														className="mt-8 w-full !rounded-none">
														Submit
													</button>
													<button
														type="button"
														disabled={f.isSubmitting}
														className="resetButton mt-2 w-full"
														onClick={() => resetFilters(f)}>
														Reset
													</button>
												</Form>
											</>
										)
									} else if (filterListShowing === "geographics") {
										return (
											<>
												<Form>
													{/*  */}
													<ul className="filterList">
														<li>
															<label>Counties</label>
															<Select
																name="counties"
																disabled={
																	geographicSelected &&
																	geographicSelected !== "counties"
																		? true
																		: false
																}
																value={f.values.counties}
																isMulti
																onChange={(k, v) => {
																	f.setFieldValue("counties", v)
																	setGeographicSelected("counties")
																	handleEmptyGeographics(v)
																}}
																options={helpers.buildPayloadOptions(data.counties)}
																onBlur={f.setFieldTouched}
																clearLayerRef={clearLayerRef}
															/>
														</li>
														<li>
															<label>Markets</label>
															<Select
																name="markets"
																isMulti
																disabled={
																	geographicSelected &&
																	geographicSelected !== "markets"
																		? true
																		: false
																}
																value={f.values.markets}
																onChange={(k, v) => {
																	f.setFieldValue("markets", v)
																	setGeographicSelected("markets")
																	handleEmptyGeographics(v)
																}}
																//options={helpers.geographicsOptions(data.markets, f.values, "markets")}
																options={helpers.buildPayloadOptions(data.markets)}
																onBlur={f.setFieldTouched}
																clearLayerRef={clearLayerRef}
															/>
														</li>
														<li>
															<label>Submarkets</label>
															<Select
																name="submarkets"
																disabled={
																	geographicSelected &&
																	geographicSelected !== "submarkets"
																		? true
																		: false
																}
																value={f.values.submarkets}
																onChange={(k, v) => {
																	f.setFieldValue("submarkets", v)
																	setGeographicSelected("submarkets")
																	handleEmptyGeographics(v)
																}}
																isMulti
																options={helpers.buildPayloadOptions(data.submarkets)}
																onBlur={f.setFieldTouched}
																clearLayerRef={clearLayerRef}
															/>
														</li>
														<li>
															<label>Zips</label>
															<Select
																name="zips"
																isMulti
																disabled={
																	geographicSelected && geographicSelected !== "zips"
																		? true
																		: false
																}
																value={f.values.zips}
																onChange={(k, v) => {
																	f.setFieldValue("zips", v)
																	setGeographicSelected("zips")
																	handleEmptyGeographics(v)
																}}
																options={helpers.buildPayloadOptions(data.zips)}
																onBlur={f.setFieldTouched}
																clearLayerRef={clearLayerRef}
															/>
														</li>
														<li>
															<label>MUD</label>
															<Select
																name="muds"
																disabled={
																	geographicSelected && geographicSelected !== "muds"
																		? true
																		: false
																}
																value={f.values.muds}
																onChange={(k, v) => {
																	f.setFieldValue("muds", v)
																	setGeographicSelected("muds")
																	handleEmptyGeographics(v)
																}}
																isMulti
																options={helpers.buildPayloadOptions(data.muds)}
																onBlur={f.setFieldTouched}
																clearLayerRef={clearLayerRef}
															/>
														</li>
														<li>
															<label>School District</label>
															<Select
																name="districts"
																disabled={
																	geographicSelected &&
																	geographicSelected !== "districts"
																		? true
																		: false
																}
																value={f.values.districts}
																onChange={(k, v) => {
																	f.setFieldValue("districts", v)
																	setGeographicSelected("districts")
																	handleEmptyGeographics(v)
																}}
																isMulti
																options={helpers.buildPayloadOptions(data.districts)}
																onBlur={f.setFieldTouched}
																clearLayerRef={clearLayerRef}
															/>
														</li>
														<li>
															<label>School</label>
															<Select
																name="schools"
																disabled={
																	geographicSelected &&
																	geographicSelected !== "schools"
																		? true
																		: false
																}
																value={f.values.schools}
																onChange={(k, v) => {
																	f.setFieldValue("schools", v)
																	setGeographicSelected("schools")
																	handleEmptyGeographics(v)
																}}
																isMulti
																options={helpers.buildPayloadOptions(data.schools)}
																onBlur={f.setFieldTouched}
																clearLayerRef={clearLayerRef}
															/>
														</li>
													</ul>
													<button
														type="submit"
														disabled={f.isSubmitting}
														className="mt-8 w-full">
														Submit
													</button>
													<button
														type="button"
														disabled={f.isSubmitting}
														className="resetButton mt-2 w-full"
														onClick={() => resetFilters(f)}>
														Reset Filters
													</button>
												</Form>
											</>
										)
									}
								}}
							</Formik>
						) : null}
					</div>
					<div
						className="absolute top-1 right-1 bg-midBlue rounded-xl px-[2px] py-3 cursor-pointer transition hover:bg-darkBlue duration-300"
						onClick={() => {
							setSideDrawerVisible(!sideDrawerVisible)
						}}>
						<svg
							className={classNames(`w-2`, {
								"transform rotate-180": sideDrawerVisible,
							})}
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 256 512">
							<path
								d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"
								fill="white"
							/>
						</svg>
					</div>
				</div>
				<div
					className="h-full relative"
					style={{
						width: sideDrawerVisible ? "80%" : "96%",
					}}>
					<div className="topBar h-[5%] flex justify-between text-xs items-center text-darkBlue font-bold border-l border-l-darkBlue">
						<div className="flex">
							{debug ? (
								<div className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 cursor-pointer">
									{zoom}
								</div>
							) : (
								""
							)}
							<div className="flex">
								<div
									className="rounded-xl bg-darkBlue text-white px-2 py-2 cursor-pointer ml-4"
									onClick={() => {
										if (!map) return
										if (!radiusMarker) {
											let center = map.getCenter()
											const marker = new mapboxgl.Marker({
												draggable: true,
											})
												.setLngLat([center.lng, center.lat])
												.addTo(map)

											const onDragEnd = () => {
												removeMarkers()
												const lngLat = marker.getLngLat()
												drawRadiusCircle(marker)
												setSelections((prev) => ({
													...prev,
													radius: {
														lat: lngLat.lat,
														lng: lngLat.lng,
														miles: radiusRef.current,
													},
												}))
											}
											marker.on("dragend", onDragEnd)
											setRadiusMarker(marker)
											drawRadiusCircle(marker)
											setSelections((prev) => ({
												...prev,
												radius: { lat: center.lat, lng: center.lng, miles: radiusRef.current },
											}))
										} else {
											removeMarkers()
											flyToCenter()
											radiusMarker.remove()
											setRadiusMarker(false)
											if (map.getLayer("circle-layer")) map.removeLayer("circle-layer")
											if (map.getSource("circle-source")) map.removeSource("circle-source")
											setSelections((prev) => ({
												...prev,
												radius: { lat: false, lng: false, miles: false },
											}))
										}
									}}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
										className={classNames(`w-4`, {
											"opacity-50": !selections?.radius?.lat,
											"opacity-100": selections?.radius?.lat,
										})}>
										<path
											d="M32 256C32 132.3 132.3 32 256 32c56.1 0 107.4 20.6 146.7 54.7l-34.1 34.1C338.1 95.3 298.8 80 256 80c-72.2 0-134.2 43.5-161.3 105.6c-3.5 8.1 .2 17.5 8.3 21.1s17.5-.2 21.1-8.3c22.2-50.9 73-86.4 132-86.4c34 0 65.2 11.8 89.9 31.5l-34.3 34.3C295.9 166.6 276.7 160 256 160c-53 0-96 43-96 96s43 96 96 96s96-43 96-96c0-8.8-7.2-16-16-16s-16 7.2-16 16c0 35.3-28.7 64-64 64s-64-28.7-64-64s28.7-64 64-64c11.9 0 23 3.2 32.5 8.9l-43.8 43.8c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l224-224c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L425.4 64C380.2 24.2 320.9 0 256 0C114.6 0 0 114.6 0 256S114.6 512 256 512s256-114.6 256-256c0-8.8-7.2-16-16-16s-16 7.2-16 16c0 123.7-100.3 224-224 224S32 379.7 32 256zm384-16c-8.8 0-16 7.2-16 16c0 59-35.5 109.8-86.4 132c-8.1 3.5-11.8 13-8.3 21.1s13 11.8 21.1 8.3C388.5 390.2 432 328.2 432 256c0-8.8-7.2-16-16-16zM102.9 305.3c-8.1 3.5-11.8 13-8.3 21.1c17.7 40.6 50.3 73.2 90.9 90.9c8.1 3.5 17.5-.2 21.1-8.3s-.2-17.5-8.3-21.1c-33.2-14.5-59.9-41.2-74.4-74.4c-3.5-8.1-13-11.8-21.1-8.3zM120 256a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zM256 440a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"
											fill="white"
										/>
									</svg>
								</div>
								{radiusMarker ? (
									<div className="flex items-center">
										<input
											type="number"
											value={radius}
											step="0.5"
											onChange={(e) => {
												updateRadius(parseFloat(e.target.value))
											}}
											className="text-black ml-2 bg-slate-200 w-14 py-1 px-2 outline-none"
										/>
										<span className="font-bold text-darkBlue pl-1">Miles</span>
									</div>
								) : null}
							</div>
							<div className="flex items-center">
								<span className="mr-2 pl-3">Polygons</span>
								<Select
									name="area"
									value={selectPolygon}
									onChange={(v, k) => {
										setSelectPolygon(k)
										drawRef.current.deleteAll()
										if (k === undefined) {
											removeMarkers()
											flyToCenter()
											setAreaSaving(false)
											setSelections(_.cloneDeep(fields))
											return
										}
										let sels = _.cloneDeep(selections)
										sels.area.features = []
										sels.area?.features.push(data?.user?.areas?.features[k])
										setSelections(sels)
										drawRef.current.add(sels.area)
										setAreaName(data?.user?.areas?.features[k]?.properties?.name)
										map.fitBounds(turf.bbox(sels.area), {
											padding: 100,
										})
									}}
									clearable={true}
									options={_.map(data?.user?.areas?.features, (v, k) => {
										return { label: v?.properties?.name, value: k }
									})}
								/>
							</div>
							<div className="ml-8 flex items-center flex-1">
								<span className="mr-2">Layers</span>
								<Select
									name="layer"
									value={layer}
									onChange={(v, k) => {
										selectedLayerRef.current = null
										removeAllLayers()
										setLayer(k)
										// Go through each of the geo layers and remove values
										_.forEach(
											[
												"zips",
												"counties",
												"markets",
												"submarkets",
												"districts",
												"schools",
												"muds",
											],
											(l) => {
												if (k === "zip" && l === "zips") return
												if (k === "county" && l === "counties") return
												if (k === "market" && l === "markets") return
												if (k === "submarket" && l === "submarkets") return
												if (k === "district" && l === "districts") return
												if (k === "school" && l === "schools") return
												if (k === "muds" && l === "muds") return
												formikRef.current.setFieldValue(l, [])
											}
										)
									}}
									clearable={true}
									className="min-w-[200px]"
									options={[
										{
											label: "Zip Codes",
											value: "zip",
										},
										{
											label: "Counties",
											value: "county",
										},
										{
											label: "Markets",
											value: "market",
										},
										{
											label: "Submarkets",
											value: "submarket",
										},
										{
											label: "Districts",
											value: "district",
										},
										{
											label: "MUDs",
											value: "muds",
										},
										{
											label: "Schools",
											value: "school",
										},
										{
											label: "Attendance Zone - Elementary",
											value: "attendance_elementary",
										},
										{
											label: "Attendance Zone - Middle",
											value: "attendance_middle",
										},
										{
											label: "Attendance Zone - High",
											value: "attendance_high",
										},
										/* {
											label: "Heat - Starts",
											value: "heat_starts",
										},
										{
											label: "Heat - Closings",
											value: "heat_closings",
										},
										{
											label: "Heat - VDLs",
											value: "heat_vdls",
										},
										{
											label: "Heat - Futures",
											value: "heat_futures",
										}, */
									]}
								/>
							</div>
						</div>
						<div className="pr-6">
							<svg
								onClick={logout}
								className="w-4 cursor-pointer"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512">
								<path
									d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
									fill="rgb(0, 92, 144)"
								/>
							</svg>
						</div>
					</div>
					<div
						className=""
						style={{
							height: bottomDrawerVisible ? "65%" : "92%",
						}}
						ref={mapContainer}>
						{areaSaving ? (
							<div className="absolute top-0 right-48 bg-white border border-neutral-400 z-30 text-xs pt-2 w-64 flex flex-col">
								<input
									type="text"
									placeholder="Area Name"
									className="border-neutral-400 border mx-2 px-2 py-1 mb-2 block"
									value={areaName}
									onChange={(e) => setAreaName(e.target.value)}
								/>
								<div className="flex">
									<div
										className="bg-gray-800 text-center text-white px-2 py-1 flex-1 cursor-pointer transition-all hover:opacity-85 duration-300"
										onClick={() => {
											modifyArea("delete", areaName)
										}}>
										Remove Favorite
									</div>
									<div
										className="bg-midBlue text-center text-white px-2 py-1 flex-1 cursor-pointer transition-all hover:opacity-85 duration-300"
										onClick={() => {
											modifyArea("save", areaName)
										}}>
										Save
									</div>
								</div>
							</div>
						) : null}
						<div className="absolute top-0 right-0 bg-white text-darkBlue flex z-50 child:cursor-pointer border border-neutral-400 text-sm shadow-lg">
							<div
								className={classNames(`border-r border-r-darkBlue px-2 py-1`, {
									"bg-darkBlue text-white": mapStyle === "mapbox://styles/mapbox/streets-v11",
								})}
								onClick={() => changeMapStyle("mapbox://styles/mapbox/streets-v11")}>
								Street
							</div>
							<div
								className={classNames(`border-r border-r-darkBlue px-2 py-1`, {
									"bg-darkBlue text-white":
										mapStyle === "mapbox://styles/mapbox/satellite-streets-v12",
								})}
								onClick={() => changeMapStyle("mapbox://styles/mapbox/satellite-streets-v12")}>
								Satellite
							</div>
							<div
								className={classNames(`px-2 py-1`, {
									"bg-darkBlue text-white": mapStyle === "mapbox://styles/mapbox/navigation-day-v1",
								})}
								onClick={() => changeMapStyle("mapbox://styles/mapbox/navigation-day-v1")}>
								Traffic
							</div>
						</div>
					</div>
					<div
						className="pr-6 py-8 relative"
						style={{
							height: bottomDrawerVisible ? "30%" : "5%",
						}}>
						<div
							className="absolute top-1 right-3 bg-midBlue rounded-xl px-4 cursor-pointer transition hover:bg-darkBlue duration-300"
							onClick={() => {
								setBottomDrawerVisible(!bottomDrawerVisible)
							}}>
							<svg
								className={classNames(`w-2`, {
									"transform rotate-180": !bottomDrawerVisible,
								})}
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 320 512">
								<path d="M320 240L160 384 0 240l0-48 320 0 0 48z" fill="white" />
							</svg>
						</div>
						<ActiveFilters />
						<BackTo />
						<div className="max-h-[500px] overflow-scroll">
							<FullTable
								data={entries}
								selections={selections}
								raw={d?.data?.entries}
								setLoading={setLoading}
								columns={columns}
								exportable={true}
								payload={data}
								viewing={viewing}
								classNames={["mainApp"]}
								onCheckboxChange={handleCheckbox}
								unset={[
									"id",
									"builders",
									"addedDate",
									"entries",
									"community",
									"comments",
									"market",
									"submarket",
									"breakout",
									"breakoutTotal",
									"marketID",
								]}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default App
