import React, { useEffect, useState, useRef } from "react"
import FullTable from "components/shared/FullTable"
import {
	useMainIngestMutation,
	useModifySubdivisionMutation,
	useMainRetrieveQuery,
	useMainPatchMutation,
} from "services/api"
import { Formik, Form, Field, ErrorMessage } from "formik"
import helpers from "helpers"
import FormError from "components/shared/FormError"
import Select from "components/Select"
import * as Yup from "yup"
import LatLngPicker from "components/back/LatLngPicker"
import NumberField from "components/shared/NumberField"
import { toast } from "react-toastify"
import Modal from "react-responsive-modal"
import Matrix from "components/Matrix"
import _ from "lodash"

function Subdivisions() {
	const q = useMainRetrieveQuery({ action: "getSubdivisions", tags: ["subdivisions"] })
	const communities = useMainRetrieveQuery({ action: "getCommunities", tags: ["communities"] })
	const options = useMainRetrieveQuery({ action: "getOptions", tags: ["options"] })
	const submarkets = useMainRetrieveQuery({ action: "getSubmarkets", tags: ["submarkets"] })
	const counties = useMainRetrieveQuery({ action: "getCounties", tags: ["counties"] })
	const developers = useMainRetrieveQuery({ action: "getDevelopers", tags: ["developers"] })
	const floorplans = useMainRetrieveQuery({ action: "getFloorplans", tags: ["developers"] })
	const [create, createData] = useMainIngestMutation()
	const [patch, patchData] = useModifySubdivisionMutation()
	const [mainPatch, mainPatchData] = useMainPatchMutation()
	const [subdivisions, setSubdivisions] = useState([])
	const [editData, setEditData] = useState(null)
	const [editModal, setEditModal] = useState(false)
	const [markerWipe, triggerMarkerWipe] = useState(false)
	const [confirmDuplicate, setConfirmDuplicate] = useState(false)
	let model = {
		name: "",
		community: "",
		city: "",
		zip: "",
		hoa_fee: "",
		tax: "",
	}
	useEffect(() => {
		if (q) {
			setSubdivisions(buildRows(q.data))
		}
	}, [q])

	const buildRows = (data) => {
		let ret = []
		_.forEach(data, (item) => {
			ret.push({
				...item,
				edit: (
					<i
						className="fa-solid fa-pen-field cursor-pointer text-primary"
						onClick={() => {
							setEditData(item)
							setEditModal(true)
						}}></i>
				),
			})
		})

		return ret
	}

	const validation = () => {
		return Yup.object().shape({
			name: Yup.string().required("Required"),
		})
	}

	const columns = [
		{
			id: "id",
			header: "ID",
		},
		{
			id: "name",
			header: "Name",
		},
		{
			id: "public_name",
			header: "Public Name",
		},
		{
			id: "developer",
			header: "Developer",
			accessorFn: (r) => helpers.translateID("developer", r, developers),
		},
		{
			id: "city",
			header: "City",
		},
		{
			id: "county",
			header: "County",
			accessorFn: (r) => helpers.translateID("county", r, counties),
		},
		{
			id: "state",
			header: "State",
		},
		{
			id: "edit",
			header: "Edit",
			filterable: false,
		},
	]

	const DisplayForm = (props) => {
		let init = _.clone(model)
		if (editData && !props?.new) init = editData
		return (
			<Formik
				initialValues={init}
				validationSchema={validation}
				onSubmit={async (values, { resetForm, setSubmitting }) => {
					let resp
					if (props?.new) {
						resp = await create({ ...values, action: "createSubdivision", tags: ["subdivisions"] })
					} else {
						resp = await patch(values)
					}
					if (resp.data?.status === 200) {
						toast.success(resp.data.message)
						resetForm()
						setEditData(null)
						setEditModal(false)
					} else if (resp.data?.status === 400) {
						toast.error("Error creating user.")
					}
					setSubmitting(false)
				}}>
				{(f) => (
					<>
						<div className="flex justify-end">
							{editModal ? (
								<a
									className="btn text-xs"
									onClick={async () => {
										if (!confirmDuplicate) {
											setConfirmDuplicate(true)
										} else {
											let resp = await mainPatch({
												id: editData.id,
												action: "duplicateSubdivision",
												tags: ["subdivisions"],
											})
											setEditModal(false)
											setConfirmDuplicate(false)
											triggerMarkerWipe(!markerWipe)
											toast.success(resp?.data?.message)
										}
									}}>
									{confirmDuplicate ? "Confirm Duplicate?" : "Duplicate Subdivision"}
								</a>
							) : null}
						</div>
						<Form>
							{/* console.log(f.values) */}
							<ul className="formList">
								<li className={helpers.formField(f, "name")}>
									<ErrorMessage name="name" component={FormError} />
									<label>Name</label>
									<Field type="text" name="name" />
								</li>
								<li className={helpers.formField(f, "public_name")}>
									<ErrorMessage name="public_name" component={FormError} />
									<label>Public Name</label>
									<Field type="text" name="public_name" />
								</li>
								<li className={helpers.formField(f, "submarket")}>
									<ErrorMessage name="submarket" component={FormError} className="text-red-500" />
									<label>Submarket</label>
									<Select
										name="submarket"
										value={f.values.submarket}
										onChange={f.setFieldValue}
										options={helpers.buildOpts(submarkets)}
										onBlur={f.setFieldTouched}
										clearable
									/>
								</li>
								<li className={helpers.formField(f, "community")}>
									<ErrorMessage name="community" component={FormError} className="text-red-500" />
									<label>Community</label>
									<Select
										name="community"
										value={f.values.community}
										onChange={f.setFieldValue}
										options={helpers.buildOpts(communities)}
										onBlur={f.setFieldTouched}
										clearable
									/>
								</li>
								<li className={helpers.formField(f, "developer")}>
									<ErrorMessage name="developer" component={FormError} className="text-red-500" />
									<label>Developer</label>
									<Select
										name="developer"
										value={f.values.developer}
										onChange={f.setFieldValue}
										options={helpers.buildOpts(developers)}
										onBlur={f.setFieldTouched}
										clearable
									/>
								</li>
								<li className={helpers.formField(f, "county")}>
									<ErrorMessage name="county" component={FormError} className="text-red-500" />
									<label>County</label>
									<Select
										name="county"
										value={f.values.county}
										clearable
										onChange={f.setFieldValue}
										options={helpers.buildOpts(counties)}
										onBlur={f.setFieldTouched}
									/>
								</li>
								<li className={helpers.formField(f, "city")}>
									<ErrorMessage name="city" component={FormError} />
									<label>City</label>
									<Field type="text" name="city" />
								</li>
								<li className={helpers.formField(f, "zip")}>
									<ErrorMessage name="zip" component={FormError} />
									<label>Zip</label>
									<Field type="text" name="zip" />
								</li>
								<li className={helpers.formField(f, "lat")}>
									<ErrorMessage name="lat" component={FormError} />
									<label>Latitude</label>
									<NumberField
										name="lat"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.lat}
										error={f.errors.lat}
										value={f.values.lat}
										thousandSeparator={true}
										float={true}
										decimals={8}
									/>
								</li>
								<li className={helpers.formField(f, "lng")}>
									<ErrorMessage name="lng" component={FormError} />
									<label>Longitude</label>
									<NumberField
										name="lng"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.lng}
										error={f.errors.lng}
										value={f.values.lng}
										thousandSeparator={true}
										decimals={8}
										float={true}
									/>
								</li>
								<li className={helpers.formField(f, "comments")}>
									<ErrorMessage name="comments" component={FormError} className="text-red-500" />
									<label>Comments</label>
									<Field component="textarea" name="comments" />
								</li>
								<li className={helpers.formField(f, "amenities")}>
									<ErrorMessage name="amenities" component={FormError} className="text-red-500" />
									<label>Amenities</label>
									<Select
										name="amenities"
										value={f.values.amenities}
										onChange={f.setFieldValue}
										options={helpers.buildDbOptions(options, "amenities")}
										onBlur={f.setFieldTouched}
										isMulti
										clearable
									/>
								</li>
								<li className={helpers.formField(f, "link")}>
									<ErrorMessage name="link" component={FormError} />
									<label>Link</label>
									<Field type="text" name="link" />
								</li>
								<li className={helpers.formField(f, "hoa_fee")}>
									<ErrorMessage name="hoa_fee" component={FormError} />
									<label>HOA Fee</label>
									<NumberField
										name="hoa_fee"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.hoa_fee}
										error={f.errors.hoa_fee}
										value={f.values.hoa_fee}
										thousandSeparator={true}
										numeric={true}
										prefix={"$"}
									/>
								</li>
								<li className={helpers.formField(f, "tax")}>
									<ErrorMessage name="tax" component={FormError} />
									<label>Tax</label>
									<NumberField
										name="tax"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.tax}
										error={f.errors.tax}
										value={f.values.tax}
										decimals={6}
										thousandSeparator={true}
										float={true}
									/>
								</li>
							</ul>
							<h3>Floorplans</h3>
							<Matrix
								delete={true}
								fields={[
									{
										id: "floorplan",
										name: "Floorplan",
										type: "select",
										options: helpers.buildOpts(floorplans, true),
									},
									{
										id: "quarter",
										name: "Quarter",
										type: "select",
										options: helpers.buildQuarters({ allTime: true }),
									},
									{
										id: "lot_type",
										name: "Lot Type",
										type: "select",
										options: helpers.buildLotTypes(),
									},
									{
										id: "price",
										name: "Price",
										type: "number",
										thousandSeparator: true,
										numeric: true,
										prefix: "$",
									},
								]}
								data={f.values.floorplan_pricing}
								onChange={(v) => f.setFieldValue("floorplan_pricing", v)}
							/>
							<h3 className="mt-24">Select Center Point</h3>
							<LatLngPicker height={400} formik={f} {...props} triggerWipe={markerWipe} />
							<button type="submit" disabled={f.isSubmitting} className="mt-12">
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		)
	}

	return (
		<div className="">
			<div className="group">
				<h2>Add Subdivisions</h2>
				<DisplayForm new={true} />
			</div>
			<h2 className="mt-12">Current Subdivisions</h2>
			<FullTable data={subdivisions} columns={columns} classNames={"small"} admin={true} />
			<Modal open={editModal} onClose={() => setEditModal(false)} center>
				<h2>Edit Subdivisions</h2>
				<DisplayForm />
			</Modal>
		</div>
	)
}

export default Subdivisions
