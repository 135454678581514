import { useResetMutation } from "services/api"
import { Formik, Form, Field, ErrorMessage } from "formik"
import helpers from "helpers"
import * as Yup from "yup"
import classnames from "classnames"
import FormError from "components/shared/FormError"
import { jwtDecode } from "jwt-decode"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

function Reset() {
	const [reset, resetData] = useResetMutation()
	const navigate = useNavigate()
	Yup.addMethod(Yup.Schema, "isPasswordStrong", function (options, errorMessage = "password must me stronger") {
		return this.test("test-is-password-strong", errorMessage, function (value) {
			const { path, createError } = this

			let isStrong = true

			// if field optional
			if (typeof value == "undefined") {
				return true
			}

			if (options?.minLowercase) {
				if (!/[a-z]/.test(String(value))) {
					isStrong = false
				}
			}
			if (options?.minUppercase) {
				if (!/[A-Z]/.test(String(value))) {
					isStrong = false
				}
			}
			if (options?.minNumbers) {
				if (!/\d/.test(String(value))) {
					isStrong = false
				}
			}
			if (options?.minSymbols) {
				if (!/\W/.test(String(value))) {
					isStrong = false
				}
			}

			return (
				isStrong ||
				createError({
					path,
					message: errorMessage,
				})
			)
		})
	})

	const submit = async (v, setSubmitting) => {
		let resp = await reset({ ...v })
		setSubmitting(false)
		toast.success("Password reset link sent to your email.")
	}

	const validation = () => {
		return Yup.object().shape({
			email: Yup.string().email("Invalid email address").required("Required"),
		})
	}
	return (
		<div className="container flex justify-center">
			<div className="w-4/12">
				<div className="text-center">
					<img
						src="https://cbas-primary.s3.us-east-2.amazonaws.com/logo-color.svg"
						className="w-1/2"
						alt="Logo"
					/>
				</div>
				<div className="flex flex-col mt-12">
					<p className="text-darkBlue text-sm">
						Please enter your email to begin the reset password process.
					</p>
					<Formik
						initialValues={{ email: "" }}
						validationSchema={validation}
						onSubmit={(values, { setSubmitting }) => {
							submit(values, setSubmitting)
						}}>
						{(f) => (
							<>
								<Form>
									<ul className="formList">
										<li className={classnames(`full ${helpers.formField(f, "email")}`)}>
											<ErrorMessage name="email" component={FormError} className="text-red-500" />
											<Field
												type="email"
												name="email"
												placeholder="Email"
												className={classnames("full mt-6", helpers.formField(f, "email"))}
											/>
										</li>
									</ul>
									<button type="submit" disabled={f.isSubmitting} className="w-full mt-8">
										Submit
									</button>
								</Form>
							</>
						)}
					</Formik>
				</div>
			</div>
		</div>
	)
}

export default Reset
