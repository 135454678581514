import { useChangePasswordMutation } from "services/api"
import { Formik, Form, Field, ErrorMessage } from "formik"
import helpers from "helpers"
import * as Yup from "yup"
import classnames from "classnames"
import FormError from "components/shared/FormError"
import { useNavigate, Link } from "react-router-dom"
import { toast } from "react-toastify"

//? This is where we get the token and reset the password, not the init.
function ResetPassword() {
	const [create] = useChangePasswordMutation()
	const navigate = useNavigate()

	const params = new URLSearchParams(window.location.search)
	const token = params.get("token")

	if (!token) return "Invalid token"

	const validationSchema = Yup.object({
		password: Yup.string()
			.min(8, "Password must be at least 8 characters")
			.matches(/[A-Z]/, "Password must contain at least one capital letter")
			.required("Required"),
		verify_password: Yup.string()
			.oneOf([Yup.ref("password")], "Passwords must match")
			.required("Required"),
	})

	return (
		<div className="container flex justify-center">
			<div className="w-4/12">
				<div className="text-center">
					<Link to="/">
						<img
							src="https://cbas-primary.s3.us-east-2.amazonaws.com/logo-color.svg"
							className="w-1/2"
							alt="Logo"
						/>
					</Link>
				</div>
				<div className="flex flex-col mt-12">
					<Formik
						initialValues={{ verify_password: "", password: "" }}
						validationSchema={validationSchema}
						onSubmit={async (values, { setSubmitting }) => {
							let resp = await create({ ...values, token, action: "changePassword", tags: [""] })
							if (resp.data?.status === 200) {
								navigate("/login")
								toast.success("Password reset successfully.  Please login.")
							}
						}}>
						{(f) => (
							<>
								<Form>
									<ul className="formList">
										<li className={classnames(`full mt-3 ${helpers.formField(f, "password")}`)}>
											<ErrorMessage
												name="password"
												component={FormError}
												className="text-red-500"
											/>
											<Field
												type="password"
												name="password"
												placeholder="Password"
												className={classnames(
													"!mb-0 mt-6 pt-8",
													helpers.formField(f, "password")
												)}
											/>
										</li>
										<li
											className={classnames(
												`full mt-3 ${helpers.formField(f, "verify_password")}`
											)}>
											<ErrorMessage
												name="verify_password"
												component={FormError}
												className="text-red-500"
											/>
											<Field
												type="password"
												name="verify_password"
												placeholder="Verify Password"
												className={classnames(
													"!mb-0 mt-6 pt-8",
													helpers.formField(f, "verify_password")
												)}
											/>
										</li>
									</ul>
									<button type="submit" disabled={f.isSubmitting} className="w-full mt-8">
										Submit
									</button>
								</Form>
							</>
						)}
					</Formik>
				</div>
			</div>
		</div>
	)
}

export default ResetPassword
