import React, { useEffect, useState, useRef } from "react"
import FullTable from "components/shared/FullTable"
import {
	useCreateEntriesMutation,
	useMainRetrieveQuery,
	useModifyEntryMutation,
	useMainPatchMutation,
} from "services/api"
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik"
import helpers from "helpers"
import FormError from "components/shared/FormError"
import Select from "components/Select"
import * as yup from "yup"
import Matrix from "components/Matrix"
import { toast } from "react-toastify"
import Modal from "react-responsive-modal"
import NumberField from "components/shared/NumberField"
import _ from "lodash"
import moment from "moment"
import classNames from "classnames"

function Entry() {
	const q = useMainRetrieveQuery({ action: "getEntries", tags: ["entries"] })
	const sections = useMainRetrieveQuery({ action: "getSections", tags: ["sections"] })
	const communities = useMainRetrieveQuery({ action: "getCommunities", tags: ["communities"] })
	const builders = useMainRetrieveQuery({ action: "getBuilders", tags: ["builders"] })
	const subdivisions = useMainRetrieveQuery({ action: "getSubdivisions", tags: ["subdivisions"] })
	const [create, createData] = useCreateEntriesMutation({
		refetchQueries: ["MainRetrieve"],
	})
	const [modify, createModify] = useModifyEntryMutation()
	const [entries, setEntries] = useState([])
	const [editData, setEditData] = useState(null)
	const [subdivision, setSubdivision] = useState(null)
	const [patch, patchData] = useMainPatchMutation()
	const [confirmDelete, setConfirmDelete] = useState(false)
	const quarter = useRef(moment().startOf("quarter").unix())
	const [editModal, setEditModal] = useState(false)
	const [setupData, setSetupData] = useState(null) // This is when they select a sub, it has to build the lot type + builder + num of lots
	const [hasBTR, setHasBTR] = useState(false)
	const [setupReady, setSetupReady] = useState(false)
	let model = {
		section: "",
		occupied: "",
		models: "",
		complete_vacant: "",
		under_construction: "",
		vdl: "",
		futures: "",
		total: "",
		builder: "",
		lot_type: "",
		num_lots: "",
		subdivision: "",
		quarter: moment().startOf("quarter").unix(),
		lot_types_builders: [],
	}

	useEffect(() => {
		//console.log("Updating Entries...")
		if (q.data) {
			setEntries(buildRows(q.data))
		}
	}, [sections.isSuccess, communities.isSuccess, builders.isSuccess, subdivisions.isSuccess, q.status])

	useEffect(() => {
		if(!Array.isArray(setupData)) return
		let btr = false
		_.forEach(setupData, (v) => {
			let section = _.find(sections?.data, { id: v.section })
			console.log(section)
			if (section?.btr) btr = true
		})
		if (btr !== hasBTR) setHasBTR(btr)
	}, [setupData])

	const buildRows = (data) => {
		let ret = []
		_.forEach(data, (item) => {
			ret.push({
				...item,
				edit: (
					<i
						className="fa-solid fa-pen-field cursor-pointer text-primary"
						onClick={() => {
							setEditData(item)
							setEditModal(true)
						}}></i>
				),
			})
		})

		return ret
	}

	const validation = () => {
		return yup.object().shape({
			section: yup.number().required("Required"),
			quarter: yup.number().required("Required"),
		})
	}
	const columns = [
		{
			id: "id",
			header: "ID",
		},
		{
			id: "quarter",
			header: "Quarter",
			accessorFn: (row) => {
				if (row.quarter) return `Q${moment.unix(row.quarter).format("Q YYYY")}`
				return ""
			},
		},
		{
			id: "sectionName",
			header: "Section",
			//accessorFn: (row) => helpers.translateID("section", row, sections),
		},
		{
			id: "lot_type",
			header: "Lot Type",
		},
		{
			id: "builder",
			header: "Builder",
			accessorFn: (row) => helpers.translateID("builder", row, builders),
		},
		{
			id: "subdivision",
			header: "Subdivision",
			accessorFn: (row) => helpers.translateID("subdivision", row, subdivisions),
		},
		{
			id: "public_name",
			header: "Public Name",
			accessorFn: (row) => helpers.colPublicName(row, subdivisions),
		},
		{
			id: "community",
			header: "MPC",
			accessorFn: (row) => helpers.translateID("community", row, communities),
		},
		{
			id: "edit",
			header: "Edit",
			filterable: false,
		},
	]

	const buildBuilders = (f) => {
		// This weeds out builders not in the section they chose
		if (!f.values.section) return []
		let store = []
		_.forEach(sections?.data, (v) => {
			_.forEach(v.lot_types_builders, (r) => {
				store.push(r.builder)
			})
		})
		let filtered = _.filter(builders?.data, (r) => store.includes(r.id))
		let ret = []
		_.forEach(filtered, (v) => {
			ret.push({ value: v.id, label: v.name })
		})

		return ret
	}

	const buildSubdivisions = () => {
		let ret = []
		if (!subdivisions?.data) return []
		_.forEach(subdivisions.data, (item) => {
			let label = `${item.name} | (${item.public_name})`
			if (item.communityName) label += ` - ${item.communityName}`
			if (item.developerName) label += ` - ${item.developerName}`
			if (item.submarketName) label += ` - ${item.submarketName}`
			if (item.city) label += ` - ${item.city}`

			ret.push({ value: item.id, label })
		})
		return ret
	}

	const DisplayForm = (props) => {
		let init = _.clone(model)
		if (editData && !props?.new) init = editData
		return (
			<Formik
				initialValues={init}
				validationSchema={validation}
				onSubmit={async (values, { resetForm, setSubmitting }) => {
					const body = new FormData()
					_.forEach(values, (v, k) => {
						if (v === "null") return
						body.append(k, v)
					})
					body.append("type", "entries")
					body.append("action", "createEntries")
					body.append("tags", ["entries"])
					let resp = await modify(body)
					if (resp.data?.status === 200) {
						toast.success(resp.data.message)
						resetForm()
						setEditData(null)
						setEditModal(false)
					} else if (resp.data?.status === 400) {
						toast.error("Error creating user.")
					}
					setSubmitting(false)
				}}>
				{(f) => (
					<>
						<Form>
							{/* console.log(f) */}
							<ul className="formList">
								<li className={helpers.formField(f, "quarter")}>
									<ErrorMessage name="quarter" component={FormError} className="text-red-500" />
									<label>Quarter</label>
									<Select
										name="quarter"
										value={f.values.quarter}
										onChange={f.setFieldValue}
										options={helpers.buildQuarters({ allTime: editData ? true : false })}
										onBlur={f.setFieldTouched}
									/>
								</li>
								<li className={helpers.formField(f, "section")}>
									<ErrorMessage name="section" component={FormError} className="text-red-500" />
									<label>Section</label>
									<Select
										name="section"
										value={f.values.section}
										onChange={f.setFieldValue}
										options={helpers.buildOpts(sections, true)}
										onBlur={f.setFieldTouched}
									/>
								</li>
								<li className={helpers.formField(f, "builder")}>
									<ErrorMessage name="builder" component={FormError} className="text-red-500" />
									<label>Builder</label>
									<Select
										name="builder"
										value={f.values.builder}
										onChange={f.setFieldValue}
										options={buildBuilders(f)}
										onBlur={f.setFieldTouched}
									/>
								</li>
								<li className={helpers.formField(f, "occupied")}>
									<ErrorMessage name="occupied" component={FormError} />
									<label>Occupied</label>
									<NumberField
										name="occupied"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.occupied}
										error={f.errors.occupied}
										value={f.values.occupied}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "models")}>
									<ErrorMessage name="models" component={FormError} />
									<label>Models</label>
									<NumberField
										name="models"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.models}
										error={f.errors.models}
										value={f.values.models}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "complete_vacant")}>
									<ErrorMessage name="complete_vacant" component={FormError} />
									<label>Finished Vacant</label>
									<NumberField
										name="complete_vacant"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.complete_vacant}
										error={f.errors.complete_vacant}
										value={f.values.complete_vacant}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "under_construction")}>
									<ErrorMessage name="under_construction" component={FormError} />
									<label>Under Construction</label>
									<NumberField
										name="under_construction"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.under_construction}
										error={f.errors.under_construction}
										value={f.values.under_construction}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "vdl")}>
									<ErrorMessage name="vdl" component={FormError} />
									<label>VDL</label>
									<NumberField
										name="vdl"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.vdl}
										error={f.errors.vdl}
										value={f.values.vdl}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "futures")}>
									<ErrorMessage name="futures" component={FormError} />
									<label>Futures</label>
									<NumberField
										name="futures"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.futures}
										error={f.errors.futures}
										value={f.values.futures}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "total")}>
									<ErrorMessage name="total" component={FormError} />
									<label>Total</label>
									<NumberField
										name="total"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.total}
										error={f.errors.total}
										value={f.values.total}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "observed_status")}>
									<ErrorMessage
										name="observed_status"
										component={FormError}
										className="text-red-500"
									/>
									<label>Observed Status</label>
									<Select
										name="observed_status"
										value={f.values.observed_status}
										onChange={f.setFieldValue}
										options={helpers.observedStatuses()}
										onBlur={f.setFieldTouched}
									/>
								</li>
								<li className={helpers.formField(f, "pdf")}>
									<ErrorMessage name="pdf" component={FormError} className="text-red-500" />
									<label>
										PDF{" "}
										{f.values.pdf ? (
											<a
												href={`https://cbas-primary.s3.us-east-2.amazonaws.com${f.values.pdf}`}
												target="_blank"
												className="text-xs text-darkBlue pl-4 font-bold">
												Current Scan
											</a>
										) : null}
									</label>
									<input
										name="pdf"
										type="file"
										className="form__input"
										onChange={(event) => {
											f.setFieldValue("pdf", event.currentTarget.files[0])
										}}
									/>
								</li>
							</ul>
							<button type="submit" disabled={f.isSubmitting} className="mt-12">
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		)
	}

	const BuildSetup = (f) => {
		if (!setupReady && subdivision) {
			let d = []
			let secs = _.filter(sections?.data, { subdivision: subdivision })
			
			secs = _.map(secs, (v) => {
				return {
					label: v.name,
					value: v.id,
				}
			})
			return (
				<div>
					<h3>Lot Types &amp; Builders</h3>
					<Matrix
						delete={true}
						initRow={true}
						fields={[
							{
								id: "section",
								name: "Section",
								type: "select",
								options: secs,
							},
							{
								id: "lot_type",
								name: "Lot Type",
								type: "select",
								options: helpers.buildLotTypes(),
							},
							{
								id: "builder",
								name: "Builder",
								type: "select",
								options: helpers.buildOpts(builders),
							},
							{
								id: "num_lots",
								name: "Number of Lots",
								type: "number",
							},
						]}
						data={d}
						onChange={(v) => (d = v)}
					/>
					<div
						className="btn inline-block mt-5"
						onClick={() => {
							setSetupData(d)
							setSetupReady(true)
						}}>
						Add Setup Data
					</div>
				</div>
			)
		}
		return ""
	}

	const BuildTable = (f) => {
		if (!subdivision || !sections || !setupReady) return null
		let init = []
		_.forEach(setupData, (v) => {
			let builder = _.find(builders?.data, { id: v.builder })
			let section = _.find(sections?.data, { id: v.id })
			init.push({
				section: v.section,
				sectionName: section?.name || "",
				builder: v.builder,
				builderName: builder?.name || "",
				lot_type: v.lot_type,
				num_lots: v.num_lots,
				subdivision,
				quarter: moment().startOf("quarter").unix(),
				observed_status: "",
				occupied: "",
				models: "",
				complete_vacant: "",
				under_construction: "",
				vdl: "",
				btr: "",
				futures: "",
			})
		})
		init = helpers.customSort(init, "sectionName")

		const blurCheck = (f) => {
			// When a number is blurred, this check runs to see if the fields equal out to the total
			_.forEach(f.values, (v, k) => {
				let orig = _.cloneDeep(v)
				let fields = ["complete_vacant", "futures", "models", "occupied", "under_construction", "vdl", "btr"]
				// Translate "" to 0's
				_.forEach(fields, (r) => {
					if (!orig[r]) orig[r] = 0
				})
				// Calculate total
				let total =
					orig.complete_vacant +
					orig.futures +
					orig.models +
					orig.occupied +
					orig.btr +
					orig.under_construction +
					orig.vdl
				orig.tracker = total - orig.num_lots
				v.tracker = orig.tracker
				f.setFieldValue(k, v)
				//arrayHelpers.replace(k, v)
			})
		}

		const Tracker = (props) => {
			let f = props.f
			let total = f.values[props.k].tracker
			return <span className={total === 0 ? "text-green-500" : "text-red-500"}>{total}</span>
		}

		const Rows = (props) => {
			let f = props.f
			let arrayHelpers = props.helpers
			let rows = []
			_.forEach(setupData, (v, k) => {
				let builder = _.find(builders?.data, { id: v.builder })
				let section = _.find(sections?.data, { id: v.section })
				rows.push(
					<tr className="child:text-xs child:text-left" key={`section-${v.section}-${v.builder}-${k}`}>
						<td>{section?.name}</td>
						<td>{builder?.name}</td>
						<td>{v.lot_type}</td>
						<td>
							{!v.isFuture && (
								<NumberField
									name={`${k}.models`}
									type="text"
									className="form__input"
									onChange={f.setFieldValue}
									numeric={v.float ? false : true}
									onBlur={() => blurCheck(f, arrayHelpers)}
									float={v.float || null}
									decimals={v.decimals || null}
								/>
							)}
						</td>
						<td>
							{!v.isFuture && (
								<NumberField
									name={`${k}.occupied`}
									type="text"
									className="form__input"
									onChange={f.setFieldValue}
									numeric={v.float ? false : true}
									onBlur={() => blurCheck(f, arrayHelpers)}
									float={v.float || null}
									decimals={v.decimals || null}
								/>
							)}
						</td>
						<td>
							{!v.isFuture && (
								<NumberField
									name={`${k}.complete_vacant`}
									type="text"
									className="form__input"
									onChange={f.setFieldValue}
									numeric={v.float ? false : true}
									onBlur={() => blurCheck(f, arrayHelpers)}
									float={v.float || null}
									decimals={v.decimals || null}
								/>
							)}
						</td>
						<td>
							{!v.isFuture && (
								<NumberField
									name={`${k}.under_construction`}
									type="text"
									className="form__input"
									onChange={f.setFieldValue}
									numeric={v.float ? false : true}
									onBlur={() => blurCheck(f, arrayHelpers)}
									float={v.float || null}
									decimals={v.decimals || null}
								/>
							)}
						</td>
						<td>
							{!v.isFuture && (
								<NumberField
									name={`${k}.vdl`}
									type="text"
									className="form__input"
									onChange={f.setFieldValue}
									numeric={v.float ? false : true}
									onBlur={() => blurCheck(f, arrayHelpers)}
									float={v.float || null}
									decimals={v.decimals || null}
								/>
							)}
						</td>
						{section?.btr && (
							<td>
								{!v.isFuture && (
									<NumberField
										name={`${k}.btr`}
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										numeric={v.float ? false : true}
										onBlur={() => blurCheck(f, arrayHelpers)}
										float={v.float || null}
										decimals={v.decimals || null}
									/>
								)}
							</td>
						)}
						<td>
							{!v.isFuture && (
								<NumberField
									name={`${k}.futures`}
									type="text"
									className="form__input"
									onChange={f.setFieldValue}
									numeric={v.float ? false : true}
									onBlur={() => blurCheck(f, arrayHelpers)}
									float={v.float || null}
									decimals={v.decimals || null}
								/>
							)}
						</td>
						<td>
							<Select
								name={`${k}.observed_status`}
								value={f.values.observed_status}
								onChange={f.setFieldValue}
								options={helpers.observedStatuses()}
								onBlur={f.setFieldTouched}
								clearable
							/>
						</td>
						<td>
							{!v.isFuture && (
								<input
									name={`${k}.pdf`}
									type="file"
									onChange={(event) => {
										f.setFieldValue(`${k}.pdf`, event.currentTarget.files[0])
									}}
								/>
							)}
						</td>
						<td>
							{v.num_lots} / <Tracker f={f} k={k} />
						</td>
					</tr>
				)
			})
			return rows
		}

		const validation = () => {
			return yup.array().of(
				yup.object().shape({
					occupied: yup.number().nullable().default(0),
					models: yup.number().nullable().default(0),
					complete_vacant: yup.number().nullable().default(0),
					under_construction: yup.number().nullable().default(0),
					vdl: yup.number().nullable().default(0),
					futures: yup.number().nullable().default(0),
					observed_status: yup.string().when("isFuture", {
						is: true,
						then: (schema) => schema.required("Required"),
						otherwise: (schema) => schema.notRequired(),
					}),
					isFuture: yup.boolean(),
					num_lots: yup.number().required(),
					tracker: yup.number().test("total-matches", "Total must match number of lots", function (value) {
						if (this.parent.isFuture) return true

						const fields = ["complete_vacant", "futures", "models", "occupied", "under_construction", "vdl", "btr"]

						const total = fields.reduce((sum, field) => {
							const fieldValue = this.parent[field]
							return sum + (fieldValue || 0)
						}, 0)

						return total === this.parent.num_lots
					}),
				})
			)
		}
		console.log(setupData)
		return (
			<Formik
				initialValues={init}
				validationSchema={validation}
				validateOnMount={true}
				onSubmit={async (values, { resetForm, setSubmitting, isValid }) => {
					// Go through the values and ensure the quarter is set to the ref's quarter
					values = _.map(values, (v) => {
						v.quarter = quarter.current
						return v
					})
					const body = new FormData()
					_.forEach(values, (v, k) => {
						_.forEach(v, (r, i) => {
							body.append(`${k}[${i}]`, r)
						})
					})
					body.append("type", "entries")
					body.append("action", "createEntries")
					body.append("tags", ["entries"])
					let resp = await create(body)
					if (resp.data?.status === 200) {
						toast.success(resp.data.message)
						resetForm()
						setEditData(null)
						setEditModal(false)
						setSubdivision(null)
					} else if (resp.data?.status === 400) {
						toast.error("Error creating user.")
					}
					setSubmitting(false)
				}}>
				{(f) => (
					<Form>
						{/* console.log(f) */}
						<FieldArray
							name="sections"
							render={(arrayHelpers) => (
								<>
									<table className="EntryTable w-full">
										<thead>
											<tr className="child:text-[11px] child:text-left child:bg-neutral-200 child:py-2 child:xl:px-2">
												<th className="w-[6%]">Section</th>
												<th className="w-[15%]">Builder</th>
												<th className="w-[6%]">Lot Type</th>
												<th className="w-[3%]">Models</th>
												<th className="w-[3%]">Occupied</th>
												<th className="w-[3%]">Finished Vacant</th>
												<th className="w-[8%]">UC</th>
												<th className="w-[4%]">VDL</th>
												{hasBTR && <th className="w-[4%]">BTR</th>}
												<th className="w-[4%]">Futures</th>
												<th className="w-[15%]">Future Status</th>
												<th className="w-[15%]">PDF</th>
												<th className="w-[5%]">Total</th>
											</tr>
										</thead>
										<tbody>{<Rows f={f} />}</tbody>
									</table>
								</>
							)}
						/>
						<button
							type="submit"
							className={classNames(`mt-12`, {
								"!bg-transparent border border-darkBlue !text-darkBlue opacity-50": !f.isValid,
							})}>
							Submit
						</button>
					</Form>
				)}
			</Formik>
		)
	}
	return (
		<div className="">
			<div className="group">
				<h2>Add Entries</h2>
				<div className="flex mb-5 items-center w-full">
					<div className="flex items-center w-4/12">
						<p className="text-darkBlue text-sm mr-3">Quarter:</p>
						<Select
							name="quarter"
							className="smallText w-full"
							value={quarter.current}
							onChange={(k, v) => {
								quarter.current = v
							}}
							options={helpers.buildQuarters()}
							onBlur={() => {}}
						/>
					</div>
					<div className="flex items-center w-full pl-4">
						<p className="text-darkBlue text-sm mr-3">Subdivision:</p>
						<Select
							name="subdivision"
							className="smallText w-full"
							value={subdivision}
							onChange={(k, v) => {
								setSubdivision(v)
								setSetupData(null)
								setHasBTR(false)
								setSetupReady(false)
							}}
							clearable
							options={buildSubdivisions()}
							onBlur={() => {}}
						/>
					</div>
				</div>
				<BuildSetup />
				<BuildTable />
			</div>
			<h2 className="mt-12">Current Entries</h2>
			<FullTable data={entries} columns={columns} classNames={"small"} pageSize={50} />
			<Modal open={editModal} onClose={() => setEditModal(false)} center>
				<h2>Edit Entry</h2>
				<div className="flex justify-end">
					<span
						className="btn !bg-red-500 !text-xs ml-5"
						onClick={async () => {
							if (!confirmDelete) {
								setConfirmDelete(!confirmDelete)
							} else {
								let resp = await patch({ id: editData.id, action: "deleteEntry", tags: ["entries"] })
								setEditModal(false)
								setConfirmDelete(false)
								toast.success(resp?.data?.message)
							}
						}}>
						{confirmDelete ? "Confirm Delete?" : "Delete Section"}
					</span>
				</div>
				<DisplayForm />
			</Modal>
		</div>
	)
}

export default Entry
